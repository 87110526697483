import { ref, onMounted, computed } from "vue";
import { useFetch, useContext } from "@nuxtjs/composition-api";
import { useMoodboards } from "@/composables/useMoodboards";
import { useRecaptcha3 } from "/composables/useRecaptcha3.js";
import { useMediaQuery } from "@vueuse/core";
import useTailwindCss from "/composables/useTailwindCss";
import axios from "axios";
import isEmpty from "lodash/isEmpty";


export default {
  __name: 'index',
  props: {
  visible: {
    type: Boolean,
    required: false,
    default: false,
  },
  slug: {
    type: String,
    required: true,
  },
},
  emits: ["hide"],
  setup(__props, { emit }) {

const props = __props;

const { screens } = useTailwindCss();
const isLGScreen = useMediaQuery(`(min-width: ${screens.lg.min})`);

const { addItem, moodboards, getMoodboards, createListWhileAddProduct } =
  useMoodboards();
const { onSubmit } = useRecaptcha3();





const moodboardsList = ref(null);
const variants = ref(null);
const loading = ref(null);

const selectedMoodboardVModel = ref({ list: "" });
const newListVModel = ref({ moodboard: "" });
const variantsVModel = ref({});
const formErrorsData = ref([]);

const { route, store } = useContext();
const { fetch, fetchState } = useFetch(async () => {
  if (!props.slug) {
    return;
  }
  const { data } = await axios.get(
    `${store.state.wsPath}v2/get-product-variants?lng=${route.value.params.locale}&sku=${props.slug}`
  );
  variants.value = data;
});

onMounted(() => {
  getMoodboards().then(() => {
    const arr = [];
    for (let i = 0; i < moodboards.value.length; i++) {
      arr.push({
        value: moodboards.value[i].slug,
        label: moodboards.value[i].title,
      });
    }
    moodboardsList.value = arr;
  });

  fetch();
});

function onInput(type, value) {
  if (!value) {
    return;
  }
  if (type == "new") {
    selectedMoodboardVModel.value.list = "";
  } else {
    newListVModel.value.moodboard = "";
  }
}

const addToMoodboardSubmitEnabled = computed(() => {
  const selected = Object.keys(variantsVModel.value).filter(
    (key) => variantsVModel.value[key] === true
  );

  return (
    (selectedMoodboardVModel.value.list?.length > 0 ||
      newListVModel.value.moodboard?.length > 0) &&
    selected.length > 0 &&
    !loading.value
  );
});

async function onAddToMoodboardSubmit() {
  const recaptchaToken = await onSubmit();
  if (recaptchaToken) {
    let response;
    loading.value = true;
    if (selectedMoodboardVModel.value.list) {
      response = await addItem(
        selectedMoodboardVModel.value.list,
        Object.keys(variantsVModel.value).join(),
        recaptchaToken
      );
    } else {
      response = await createListWhileAddProduct(
        newListVModel.value.moodboard,
        Object.keys(variantsVModel.value).join(),
        recaptchaToken
      );
    }

    if (response.status == "success") {
      emit("hide");
    } else {
      formErrorsData.value = [store.state.labels.something_went_wrong];
    }
  } else {
    formErrorsData.value = [store.state.labels.something_went_wrong];
  }

  loading.value = false;
}

return { __sfc: true,screens, isLGScreen, addItem, moodboards, getMoodboards, createListWhileAddProduct, onSubmit, props, emit, moodboardsList, variants, loading, selectedMoodboardVModel, newListVModel, variantsVModel, formErrorsData, route, store, fetch, fetchState, onInput, addToMoodboardSubmitEnabled, onAddToMoodboardSubmit, isEmpty }
}

}