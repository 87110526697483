import { ref, watch } from "vue";
import { useContext } from "@nuxtjs/composition-api";
import { useRecaptcha3 } from "/composables/useRecaptcha3.js";
import { useUser } from "/composables/useUser";
import { useDataLayer } from "/composables/useDataLayer.js";


export default {
  __name: 'index',
  props: {
  title: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  useSignup: {
    type: Boolean,
    required: false,
    default: true,
  },
  onSigninSuccess: {
    type: Function,
    required: false,
    default: () => {},
  },
},
  setup(__props) {

const props = __props;

const { login, passwordRecovery } = useUser();
const { onSubmit } = useRecaptcha3();
const { store } = useContext();
const { pushDataLayer } = useDataLayer();
const submitState = ref(null);



const formValues = ref({
  email: "",
  password: "",
});

const formErrorsData = ref([]);

const usePasswordRecovery = ref(false);
const passwordRecoveryEmailSent = ref(false);

watch(usePasswordRecovery, (value) => {
  formErrorsData.value = [];
});

async function handleUserLogin() {
  try {
    const recaptchaToken = await onSubmit();
    if (recaptchaToken) {
      submitState.value = "submitting";
      const response = await login({
        email: formValues.value.email,
        password: formValues.value.password,
        recaptcha: recaptchaToken,
      });
      if (response.token) {
        pushDataLayer({
          event: "login",
        });
        props.onSigninSuccess();
      } else {
        if (response.code == "[jwt_auth] incorrect_password") {
          formErrorsData.value = [store.state.labels.wrong_password];
        } else if (response.code == "[jwt_auth] empty_username") {
          formErrorsData.value = [store.state.labels.wrong_email];
        } else if (response.error == "not confirmed user") {
          formErrorsData.value = [store.state.labels.not_activated_user_text];
        } else {
          formErrorsData.value = [store.state.labels.something_went_wrong];
        }
      }
      submitState.value = null;
    }
  } catch (e) {
    console.log(e);
    formErrorsData.value = [store.state.labels.something_went_wrong];
    submitState.value = null;
  }
}

async function handlePasswordRecovery() {
  try {
    const recaptchaToken = await onSubmit();
    if (recaptchaToken && recaptchaToken !== null) {
      const response = await passwordRecovery({
        email: formValues.value.email,
        recaptcha: recaptchaToken,
      });
      if (response.status == "success") {
        passwordRecoveryEmailSent.value = true;
      } else if (response.error == "no email found") {
        formErrorsData.value = [store.state.labels.email_not_found];
      } else {
        formErrorsData.value = [store.state.labels.something_went_wrong];
      }
    }
  } catch (e) {
    formErrorsData.value = [store.state.labels.something_went_wrong];
  }
}

return { __sfc: true,login, passwordRecovery, onSubmit, store, pushDataLayer, submitState, props, formValues, formErrorsData, usePasswordRecovery, passwordRecoveryEmailSent, handleUserLogin, handlePasswordRecovery }
}

}