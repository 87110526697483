import { ref, computed, watch } from "vue";
import { useRoute } from "@nuxtjs/composition-api";
import { parsePhoneNumberFromString } from "libphonenumber-js";


export default {
  __name: 'index',
  props: {
  title: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  stretched: {
    type: Boolean,
    required: false,
    default: false,
  },
  pressed: {
    type: Boolean,
    required: false,
    default: false,
  },
  size: {
    type: String,
    required: false,
    validator: function (value) {
      return ["big", "regular", "small"].indexOf(value) !== -1;
    },
    default: "regular",
  },
  to: {
    type: [String, Object],
    required: false,
  },
  download: {
    type: [String, Object],
    required: false,
  },
  externalLink: {
    type: String,
    required: false,
  },
  phone: {
    type: String,
    required: false,
    validator: function (value) {
      return parsePhoneNumberFromString(value) ? true : false;
    },
  },
  email: {
    type: String,
    required: false,
    validator: function (value) {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        value
      );
    },
  },
  downloadPdfAsNewTab: {
    type: Boolean,
    required: false,
    default: true,
  },
  type: {
    type: String,
    required: false,
    validator: function (value) {
      return ["button", "submit"].indexOf(value) !== -1;
    },
    default: "button",
  },
  buttonTag: {
    type: String,
    required: false,
    validator: function (value) {
      return ["summary", "button"].indexOf(value) !== -1;
    },
    default: "button",
  },
},
  setup(__props) {

const props = __props;



const route = useRoute();
const showTitle = ref(true);
let downloadAttr;

const attrs = computed(() => {
  let value = {
    is: null,
    type: null,
    to: null,
    href: null,
    target: null,
    tabindex: null,
    role: null,
  };

  if (props.to) {
    value.is = "nuxt-link";
    if (typeof props.to === "string") {
      value.to = `/${route.value.params.locale}${props.to}`;
    } else {
      value.to = {
        path: `/${route.value.params.locale}${props.to.path}`,
        query: props.to.query,
      };
    }
  } else if (
    props.externalLink ||
    props.phone ||
    props.email ||
    props.download
  ) {
    value.is = "a";
    if (props.externalLink) {
      value.href = props.externalLink;
      value.target = "_blank";
    } else if (props.phone) value.href = "tel:" + props.phone;
    else if (props.email) value.href = encodeURI(`mailto:${props.email}`);
    else if (props.download) {
      // https://stackoverflow.com/questions/23872902/chrome-download-attribute-not-working
      // https://stackoverflow.com/questions/71154887/how-to-download-a-file-with-particular-name-via-using-download-attribute
      // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a?retiredLocale=it
      // if PDF open in newTab instead of download
      const fileType =
        typeof props.download === "object"
          ? props.download.href.slice(props.download.length - 4)
          : props.download.slice(props.download.length - 4);

      if (props.downloadPdfAsNewTab && fileType === ".pdf") {
        value.target = "_blank";
      } else {
        setDownloadAttr();
      }

      // value.href = encodeURI(typeof props.download === 'object' ? props.download.href : props.download);
      value.href =
        typeof props.download === "object"
          ? props.download.href
          : props.download;
    }
  } else {
    value.is = props.buttonTag;
  }

  if (props.disabled && (attrs.is === "nuxt-link" || attrs.is === "a")) {
    value.tabindex = -1;
  }

  if (attrs.is === "nuxt-link" || attrs.is === "a" || value.is === "summary") {
    value.role = "link";
  } else if (value.is === "button") {
    value.type = props.type;
  }

  return value;
});

function setDownloadAttr() {
  downloadAttr = props.download
    ? {
        download: typeof props.download === "object" ? props.download.name : "",
      }
    : null;
}

watch(() => props.download, setDownloadAttr);

setDownloadAttr();

return { __sfc: true,props, route, showTitle, downloadAttr, attrs, setDownloadAttr }
}

}