import { ref, computed } from "vue";
import { useContext } from "@nuxtjs/composition-api";
import { useLocalDate } from "/composables/useLocalDate";
import axios from "axios";

const state = ref({
  moodboards: [],
});

function compare(a, b) {
  // if (new Date(a.update_date_unix) - new Date(b.update_date_unix)) {
  //   return -1;
  // }
  if (new Date(a.update_date_unix) + new Date(b.update_date_unix)) {
    return 1;
  }
  // a must be equal to b
  return 0;
}

export const useMoodboards = () => {
  const { store, route } = useContext();
  const { unixToLocalDay } = useLocalDate();

  async function callApi(url) {
    url = `${url}&user_id=${store.state.user.id}`;
    const { data } = await axios.get(encodeURI(url));

    await getMoodboards();

    return data;
  }

  async function addMoodboard(listTitle, recaptcha) {
    return await callApi(
      `${store.state.wsPath}v2/form_moodboard-create-list?title=${listTitle}&recaptcha=${recaptcha}`
    );
  }

  async function getMoodboards() {
    const { data } = await axios.get(
      `${store.state.wsPath}v2/get-existing-moodboards?all=true&user_id=${store.state.user.id}`
    );

    if (data.items) {
      for (let i = 0; i < data.items.length; i++) {
        const unixDate = data.items[i].update_date_unix;
        data.items[i].update_date = unixToLocalDay(unixDate);
      }
      state.value.moodboards = data.items.sort(compare);
    }
    return data;
  }

  async function removeMoodboard(listId, recaptcha) {
    return await callApi(
      `${store.state.wsPath}v2/form_moodboard-remove-list?slug=${listId}&recaptcha=${recaptcha}`
    );
  }

  async function editMoodboard(listId, listTitle, recaptcha) {
    return await callApi(
      `${store.state.wsPath}v2/form_moodboard-edit-list?slug=${listId}&title=${listTitle}&recaptcha=${recaptcha}`
    );
  }

  async function duplicateMoodboard(listId, listTitle, recaptcha) {
    return await callApi(
      `${store.state.wsPath}v2/form_moodboard-duplicate-list?slug=${listId}&title=${listTitle}&recaptcha=${recaptcha}`
    );
  }

  async function addItem(listId, sku, recaptcha) {
    return await callApi(
      `${store.state.wsPath}v2/form_moodboard-add-product-to-existing-list?slug=${listId}&variant-slug=${sku}&recaptcha=${recaptcha}`
    );
  }

  async function removeItem(listId, sku, recaptcha) {
    return await callApi(
      `${store.state.wsPath}v2/form_moodboard-remove-product-from-existing-list?slug=${listId}&variant-slug=${sku}&recaptcha=${recaptcha}`
    );
  }

  async function createListWhileAddProduct(listTitle, sku, recaptcha) {
    return await callApi(
      `${store.state.wsPath}v2/form_moodboard-create-list-while-add-product?title=${listTitle}&variant-slug=${sku}&recaptcha=${recaptcha}`
    );
  }

  https: return {
    moodboards: computed(() => state.value.moodboards),
    addMoodboard,
    getMoodboards,
    removeMoodboard,
    editMoodboard,
    duplicateMoodboard,
    createListWhileAddProduct,
    addItem,
    removeItem,
  };
};
