import { onMounted } from "vue";
import { templateRef } from "@vueuse/core";


export default {
  __name: 'index',
  props: {
  icon: {
    type: String,
    required: true,
    validator: function (value) {
      return (
        [
          "chevron-down",
          "chevron-up",
          "chevron-big-right",
          "chevron-big-left",
          "chevron-left",
          "chevron-right",
          "search",
          "account",
          "download",
          "external-link",
          "heart-empty",
          "plus",
          "minus",
          "close",
          "hamburger",
          "edit",
          "trash",
          "share",
          "duplicate",
          "sound-off",
          "sound-on",
          "pause",
          "play",
          "fullscreen-off",
          "fullscreen-on",
          "cart"
        ].indexOf(value) !== -1
      );
    },
  },
  size: {
    type: String,
    required: false,
    validator: function (value) {
      return ["3", "4", "5", "6", "8", "12"].indexOf(value) !== -1;
    },
  },
},
  setup(__props) {

const props = __props;



const tpl_root = templateRef("tpl_root");

onMounted(() => {
  let elements = tpl_root.value.querySelectorAll("*");

  elements.forEach((current) => {
    current.setAttribute("vector-effect", "non-scaling-stroke");
  });
});

return { __sfc: true,props, tpl_root }
}

}