import { ref, watch, nextTick } from "vue";
import { useContext } from "@nuxtjs/composition-api";
import { templateRef } from "@vueuse/core";
import axios from "axios";
import throttle from "lodash/throttle";


export default {
  __name: 'index',
  props: {
  visible: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props) {

const props = __props;



const { store, route } = useContext();
const vModel = ref({ keyword: "" });
const isLoading = ref(false);
const results = ref([]);
const tpl_inputWrapper = templateRef("tpl_inputWrapper");
let focusTimeout;

const CancelToken = axios.CancelToken;
let source;

const throttledHandleSubmit = throttle(handleSubmit, 1000, {
  leading: false,
});

function handleSubmit() {
  // cancel the request
  if (source) {
    source.cancel("Operation canceled by the user.");
    source = null;
  }

  if (vModel.value.keyword?.length > 2) {
    isLoading.value = true;

    source = CancelToken.source();

    axios
      .get(
        `${store.state.wsPath}v2/form_organic-search?lng=${route.value.params.locale}&term=${vModel.value.keyword}`,
        { cancelToken: source.token }
      )
      .then(({ data }) => {
        isLoading.value = false;
        if (data.results && data.results.length) {
          results.value = data.results;
        } else {
          results.value = [];
        }
      });
  } else {
    isLoading.value = false;
    results.value = [];
  }
}

watch(
  () => props.visible,
  (value) => {
    if (value) {
      nextTick(() => {
        nextTick(() => {
          if (focusTimeout) clearInterval(focusTimeout);
          focusTimeout = setTimeout(() => {
            if (tpl_inputWrapper.value)
              tpl_inputWrapper.value.querySelector("input").focus();
          }, 510);
        });
      });
    } else {
      if (focusTimeout) clearInterval(focusTimeout);
      results.value = [];
      isLoading.value = false;
      vModel.value = { keyword: "" };
    }
  }
);

return { __sfc: true,props, store, route, vModel, isLoading, results, tpl_inputWrapper, focusTimeout, CancelToken, source, throttledHandleSubmit, handleSubmit }
}

}