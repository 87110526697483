import { useDataLayer } from "/composables/useDataLayer.js";

// if a string ends with / remove it

export default {
  __name: 'index',
  props: {
  dataProvider: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const removeTrailingSlash = (str) => {
  if (str.substr(-1) === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
};



const { pushDataLayer } = useDataLayer();

return { __sfc: true,removeTrailingSlash, props, pushDataLayer }
}

}