import { ref, onMounted } from "vue";

export default {
  __name: 'index',
  setup(__props) {

const showModal = ref(false);
const currentYear = ref(new Date().getFullYear().toString());

onMounted(() => {
  // in case of SSG
  currentYear.value = new Date().getFullYear().toString();
});

return { __sfc: true,showModal, currentYear }
}

}