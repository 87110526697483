import parser from "accept-language-parser";
import Vue from "vue";
import redirects from "../assets/js/redirects.js";
import find from "lodash/find";

export default async ({ route, store, redirect, from, req, $cookies }) => {
  if (!route.matched.length) {
    return redirect("/xx/404");
  }

  const redirectRule = find(redirects, (el) => {
    return new RegExp(el.from.replace(/\?/g, "\\?")).test(route.fullPath);
  });

  if (redirectRule) {
    redirect(redirectRule.to);
    return;
  }

  if (process.server) {
    if (isValidLocale(route.params.locale)) {
      $cookies.set("glmr_lang", route.params.locale, {
        path: "/",
        maxAge: 60 * 60 * 24 * 365, // 1 anno
      });
      store.commit("setLang", route.params.locale);
    } else {
      if ($cookies.get("glmr_lang")) {
        store.commit("setLang", $cookies.get("glmr_lang"));
        return redirectTo($cookies.get("glmr_lang"));
      } else {
        const detectedLanguages = parseLanguages(req);
        let detectedLanguage = "en";
        for (let i = 0; i < detectedLanguages.length; i++) {
          if (isValidLocale(detectedLanguages[i].code)) {
            detectedLanguage = detectedLanguages[i].code;
            store.commit("setLang", detectedLanguage);
            break;
          }
        }
        if (isValidLocale(detectedLanguage)) {
          store.commit("setLang", detectedLanguage);
          return redirectTo(detectedLanguage);
        }
      }
    }
  }

  store.commit("setLang", route.params.locale);

  function redirectTo(lang) {
    return redirect(getRedirectURL(lang));
  }

  if (process.client) {
    store.commit("setFromRoute", {
      fromParams: from ? from.params : {},
      fromFullPath: from ? from.fullPath : "",
      fromName: from ? from.name : "",
      toFullPath: route.name,
      toName: route.name,
    });
  }

  function parseLanguages(request) {
    const headers = req && req.headers ? Object.assign({}, req.headers) : {};
    const languageHeader = headers["accept-language"];
    const languages = parser.parse(languageHeader);
    return languages;
  }

  function isValidLocale(locale) {
    return /^it$|^en$|^de$|^fr$|^es$/.test(locale);
  }

  function getRedirectURL(lang) {
    if (route.params.locale == "xx") {
      return route.fullPath.replace("xx", lang);
    }

    return lang;
  }
};
