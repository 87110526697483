export default async ({ store, route }) => {
  if (route.name === "locale-checkout" && !store.state.paypalClientToken) {
    try {
      const response = await fetch(
        `${store.state.protocol}://${store.state.host}/sm/api/paypal/client-token`
      );
      const data = await response.json();
      store.commit("setPaypalClientToken", data.clientToken);
    } catch (err) {
      console.log(err);
    }
  }
};
