import { useContext } from "@nuxtjs/composition-api";

let wheelOpt = false;
let supportsPassive = false;
let wheelEvent = "wheel";
let init = false;

export const useScrollDisable = () => {
  const ctx = useContext();

  // left: 37, up: 38, right: 39, down: 40,
  // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36

  const keys = {
    37: 1,
    38: 1,
    39: 1,
    40: 1,
  };

  const enableOnSelectors = [
    ".scrollable-area",
    ".overflow-auto",
    ".overflow-visible",
    ".overflow-scroll",
    ".overflow-x-auto",
    ".overflow-y-auto",
    ".overflow-x-scroll",
    ".overflow-y-scroll",
  ];

  function preventDefault(e) {
    let scrollable;
    for (let i = 0; i < enableOnSelectors.length; i++) {
      scrollable = e.target.closest(enableOnSelectors[i]);
      if (scrollable) {
        break;
      }
    }

    if (
      !scrollable ||
      (scrollable && scrollable.scrollHeight <= scrollable.offsetHeight)
    ) {
      e.preventDefault();
    }
  }

  function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
      preventDefault(e);
      return false;
    }
  }

  function initScroll() {
    // modern Chrome requires { passive: false } when adding event
    try {
      window.addEventListener(
        "test",
        null,
        Object.defineProperty({}, "passive", {
          get: function () {
            supportsPassive = true;
          },
        })
      );
    } catch (e) {}

    wheelOpt = supportsPassive ? { passive: false } : false;
    wheelEvent =
      "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

    console.log(
      "%cScroll enabled",
      "color:#3eff3e; border: 1px solid #3eff3e; font-size: 16px; padding: 5px"
    );
  }

  // call this to Disable
  function disableScroll() {
    if (!init) {
      init = true;
      initScroll();
    }

    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener("touchmove", preventDefault, wheelOpt);
    window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
    window.addEventListener(wheelEvent, preventDefault, { passive: false }); // modern desktop
    window.addEventListener("touchmove", preventDefault, { passive: false }); // mobile
    window.addEventListener("keydown", preventDefaultForScrollKeys, false);
    console.log(
      "%cScroll has been disabled except for the following selectors:",
      "color:yellow; border: 1px solid yellow; font-size: 18px; padding: 50px",
      enableOnSelectors
    );
  }

  // call this to Enable
  function enableScroll() {
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener("touchmove", preventDefault, wheelOpt);
    window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
  }

  return {
    disableScroll,
    enableScroll,
  };
};
