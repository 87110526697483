export default {
  __name: 'index',
  props: {
  loading: {
    type: Boolean,
    required: true,
  },
  itemsPerPage: {
    type: Number,
    required: false,
  },
  loadMoreUrl: {
    type: Function,
    required: false,
  },
},
  setup(__props) {

const props = __props;



return { __sfc: true,props }
}

}