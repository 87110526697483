import { ref } from "vue";
import { useUser } from "/composables/useUser";

export default {
  __name: 'index',
  props: {
  title: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  pic: {
    type: Object,
    required: true,
  },
  pressRelease: {
    type: String,
    required: false,
  },
  images: {
    type: String,
    required: false,
  },
},
  setup(__props) {

const props = __props;


const { logged } = useUser();
const showModal = ref(false);

function checkUser(event) {
  if (!logged.value) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();

    showModal.value = true;
  }
}

return { __sfc: true,props, logged, showModal, checkUser }
}

}