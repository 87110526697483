import { useDataLayer } from "/composables/useDataLayer.js";

export default {
  __name: 'index',
  props: {
  title: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  to: {
    type: String,
    required: true,
  },
  pic: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const { pushDataLayer } = useDataLayer();



return { __sfc: true,pushDataLayer, props }
}

}