import { render, staticRenderFns } from "./index.vue?vue&type=template&id=07c688b8"
import script from "./index.vue?vue&type=script&setup=true&lang=js"
export * from "./index.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InputSelect: require('/var/www/html/glamoraV2/frontend/components/input/select/index.vue').default,InputText: require('/var/www/html/glamoraV2/frontend/components/input/text/index.vue').default,FormErrors: require('/var/www/html/glamoraV2/frontend/components/form/errors/index.vue').default,BtnTertiary: require('/var/www/html/glamoraV2/frontend/components/btn/tertiary/index.vue').default,InputCheckbox: require('/var/www/html/glamoraV2/frontend/components/input/checkbox/index.vue').default,CardProductVariant: require('/var/www/html/glamoraV2/frontend/components/card/product/variant/index.vue').default,Loader: require('/var/www/html/glamoraV2/frontend/components/loader/index.vue').default,ModalAside: require('/var/www/html/glamoraV2/frontend/components/modal/aside/index.vue').default})
