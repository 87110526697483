import { ref, computed } from "vue";
import { useCdn } from "/composables/useCdn";


export default {
  __name: 'index',
  props: {
  variant: {
    type: String,
    required: true,
    validator: function (value) {
      return ["static", "motion"].indexOf(value) !== -1;
    },
  },
  fetchpriority: {
    type: String,
    required: false,
    validator: function (value) {
      return ["high", "low", "auto"].indexOf(value) !== -1;
    },
    default: "auto",
  },
  width: {
    type: [Number, String],
    required: false,
  },
  height: {
    type: [Number, String],
    required: false,
  },
  sizes: {
    type: Object,
    required: false,
    default: function () {
      return {
        base: 1000,
        sm: 1100,
        md: 1200,
        lg: 1300,
        xl: 1400,
        "2xl": 1500,
      };
    },
  },
  src: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    required: true,
  },
  loading: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "eager", "lazy"].indexOf(value) !== -1;
    },
    default: "auto",
  },
},
  setup(__props) {

const props = __props;



const cdn = ref(/:\/\/res.cloudinary.com/.test(props.src));

const { getSrcset } = useCdn();

const videoPreload = computed(() => {
  let s;
  if (props.loading === "lazy") s = "none";
  else if (props.loading === "eager") s = "metadata";
  else s = "auto";

  return s;
});

const srcset = ref(getSrcset(props.src, props.sizes));

return { __sfc: true,props, cdn, getSrcset, videoPreload, srcset }
}

}