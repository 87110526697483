import { ref, watch } from "vue";
import { useFiltersToolbar } from "/composables/useFiltersToolbar";
import useTailwindCss from "/composables/useTailwindCss";
import isEmpty from "lodash/isEmpty";

import {
  templateRef,
  onClickOutside,
  useWindowScroll,
  useMediaQuery,
} from "@vueuse/core";


export default {
  __name: 'index',
  props: {
  useSwitch: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;



const {
  vModel,
  filtersQuery,
  updateFilters,
  updateFiltersModel,
  loadFilteredItems,
  toolbarFacets: facets,
  filtersInteractive,
} = useFiltersToolbar();

const { y } = useWindowScroll();
const { screens } = useTailwindCss();
const showModal = ref(false);
const currentFacet = ref(null);
const tpl_root = templateRef("tpl_root");
const isLGScreen = useMediaQuery(`(min-width: ${screens.lg.min})`);

function showFacet(facet = null) {
  if (facet) {
    currentFacet.value = currentFacet.value === facet ? null : facet;
  } else {
    currentFacet.value = null;
  }
}

function sortFacet(facet) {
  facet = Object.entries(facet).map(([key, value]) => {
    return { label: value, value: key };
  });
  return facet.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
}

onClickOutside(tpl_root, (event) => {
  if (currentFacet.value) {
    showFacet();
  }
});

watch(isLGScreen, (newVal, olVal) => {
  currentFacet.value = null;
  showModal.value = false;
});

watch(y, (newVal, olVal) => {
  currentFacet.value = null;
});

return { __sfc: true,props, vModel, filtersQuery, updateFilters, updateFiltersModel, loadFilteredItems, facets, filtersInteractive, y, screens, showModal, currentFacet, tpl_root, isLGScreen, showFacet, sortFacet, isEmpty }
}

}