import { watch, ref } from "vue";
import { useContext } from "@nuxtjs/composition-api";
import { useIntersectionObserver, onClickOutside } from "@vueuse/core";
import omit from "lodash/omit";


export default {
  __name: 'index',
  props: {
  openBelow: {
    type: Boolean,
    required: false,
    default: true,
  },
},
  setup(__props) {

const props = __props;



const { route } = useContext();

const allLangs = {
  en: "En",
  it: "It",
  de: "De",
  es: "Es",
  fr: "Fr",
};

const langs = omit(allLangs, route.value.params.locale);

const tpl_root = ref(null);
const tpl_mainBtn = ref(null);
const currentLangIndex = ref(null); //route.value.params.locale);
const showMenu = ref(false);

const { stop } = useIntersectionObserver(
  tpl_mainBtn,
  ([{ isIntersecting }], observerElement) => {
    if (isIntersecting === false) showMenu.value = false;
  }
);
onClickOutside(tpl_root, (event) => (showMenu.value = false));
watch(currentLangIndex, (newVal, oldVal) => {
  if (newVal != oldVal) {
    window.location = route.value.fullPath.replace(
      `/${route.value.params.locale}`,
      `/${newVal}`
    );
  }
});

return { __sfc: true,props, route, allLangs, langs, tpl_root, tpl_mainBtn, currentLangIndex, showMenu, stop }
}

}