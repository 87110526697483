export const useLogger = () => {
  function log(params) {
    const { data, filename, folder } = params;
    if (!data || !filename) {
      return;
    }

    // if (process.env.NODE_ENV !== "production") {
    //   return;
    // }

    fetch("/sm/api/logger/log", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data,
        name: `${
          folder ? folder + "/" : ""
        }[${new Date().toISOString()}]${filename}`,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("/sm/api/logger/log", data);
      });
  }

  return {
    log,
  };
};
