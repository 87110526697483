import { computed } from "vue";


export default {
  __name: 'index',
  props: {
  name: {
    type: String,
    required: false,
  },
  inputKey: {
    type: String,
    required: false,
  },
  label: {
    type: String,
    required: false,
  },
  placeholder: {
    type: String,
    required: false,
  },
  options: {
    type: [Object, Array],
    required: false,
  },
  optionGroups: {
    type: Object,
    required: false,
  },
  help: {
    type: String,
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  validation: {
    type: String,
    required: false,
  },
  validationMessages: {
    type: Object,
    required: false,
  },
},
  setup(__props) {

const props = __props;



const labelText = computed(() => {
  let s = props.label;
  if (props.label && props.validation) s += "*";

  return s;
});

const inputClass = computed(() => {
  const base = `appearance-none bg-no-repeat bg-[length:16px_16px] bg-[position:calc(100%-var(--sp-md)),center] block text-body-big border border-skin-muted w-full pl-md pr-3xl truncate -mt-px h-18 transition-colors duration-300 ease-in-out`;
  const interactive = props.disabled
    ? `disabled:!text-skin-base disabled:!border-skin-muted disabled:!bg-skin-muted`
    : `focus:!outline-none focus:border-skin-base focus:text-skin-base valid:border-skin-base bg-angle-down`;

  return `${base} ${interactive}`;
});

const labelClass = computed(() => {
  const base = `relative z-10 block text-skin-base text-body-small truncate`;
  const interactive = props.disabled ? `!text-skin-muted` : ``;

  return `${base} ${interactive}`;
});

return { __sfc: true,props, labelText, inputClass, labelClass }
}

}