import { useStore } from "@nuxtjs/composition-api";
import { computed } from "vue";

export const useJobRoles = () => {
  const store = useStore();

  const jobs = [
    { value: "F00", label: store.state.labels.jobrole_F00 },
    { value: "E00", label: store.state.labels.jobrole_E00 },
    { value: "J00", label: store.state.labels.jobrole_J00 },
    { value: "D00", label: store.state.labels.jobrole_D00 },
    { value: "G00", label: store.state.labels.jobrole_G00 },
    { value: "K00", label: store.state.labels.jobrole_K00 },
    { value: "M00", label: store.state.labels.jobrole_M00 },
    { value: "H00", label: store.state.labels.jobrole_H00 },
    { value: "A00", label: store.state.labels.jobrole_A00 },
    { value: "B00", label: store.state.labels.jobrole_B00 },
    { value: "C00", label: store.state.labels.jobrole_C00 },
    { value: "L00", label: store.state.labels.jobrole_L00 },
  ];

  const getters = {};
  const actions = {};

  return {
    jobRoles: computed(() => jobs),
    ...getters,
    ...actions,
  };
};
