import axios from "axios";
import { ref } from "vue";
import { useContext } from "@nuxtjs/composition-api";
import { useCountries } from "/composables/useCountries";
import { useJobRoles } from "/composables/useJobRoles";
import { useRecaptcha3 } from "/composables/useRecaptcha3.js";
import { useDataLayer } from "/composables/useDataLayer.js";


export default {
  __name: 'index',
  setup(__props) {

const { countries } = useCountries();
const { jobRoles } = useJobRoles();
const { onSubmit } = useRecaptcha3();
const { store, route } = useContext();
const { pushDataLayer } = useDataLayer();
const vModel = ref();
const formErrorsData = ref([]);
const requestSuccess = ref(false);
const loading = ref(false);

async function handleSubmit() {
  const recaptchaToken = await onSubmit();

  if (recaptchaToken && recaptchaToken !== null) {
    formErrorsData.value = [];

    try {
      loading.value = true;
      const response = await axios
        .post(`${store.state.wsPath}v2/form_newsletter`, {
          recaptcha: recaptchaToken,
          type: "newsletter",
          lang: route.value.params.locale,
          ...vModel.value,
        })
        .catch(function (error) {
          console.log("form_newsletter:", error);
        });
      loading.value = false;

      if (response.status == 200 && response.data.status == "success") {
        requestSuccess.value = true;

        pushDataLayer({
          event: "submit_newsletter_form",
          customer_country: vModel.value.country,
          customer_profession: vModel.value.jobrole,
        });

        setTimeout(() => {
          window.$nuxt.$formulate.reset("newsletter");
          formErrorsData.value = [];
          requestSuccess.value = false;
        }, 6000);
      } else {
        formErrorsData.value = [store.state.labels.something_went_wrong];
      }
    } catch (e) {
      console.log(e);
      formErrorsData.value = [store.state.labels.something_went_wrong];
    }
  } else {
    formErrorsData.value = [store.state.labels.something_went_wrong];
  }
}

return { __sfc: true,countries, jobRoles, onSubmit, store, route, pushDataLayer, vModel, formErrorsData, requestSuccess, loading, handleSubmit }
}

}