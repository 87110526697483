module.exports = {
  content: [
    "./components/**/*.{js,vue,ts}",
    "./layouts/**/*.vue",
    "./pages/**/*.vue",
    "./plugins/**/*.{js,ts}",
    "./nuxt.config.{js,ts}",
  ],
  theme: {
    fontFamily: {
      "family-light": ["GoodSans-Light", "Arial", "sans-serif"],
      "family-regular": ["GoodSans-Regular", "Arial", "sans-serif"],
    },
    screens: {
      sm: {
        min: "720px",
      },
      md: {
        min: "964px",
      },
      lg: {
        min: "1248px",
      },
      xl: {
        min: "1512px",
      },
      "2xl": {
        min: "1920px",
      },
      "has-hover": { raw: "(hover: hover) and (pointer: fine)" },
    },
    aspectRatio: {
      auto: "auto",
      "1/1": "1 / 1",
      "3/1": "3 / 1",
      "3/2": "3 / 2",
      "5/3": "5 / 3",
      "4/5": "4 / 5",
      "8/9": "8 / 9",
      "9/16": "9 / 16",
      "16/9": "16 / 9",
      "9/32": "9 / 32",
      "35/44": "35 / 44",
    },
    colors: {
      transparent: "transparent",
      current: "currentColor",
      white: "#ffffff",
      black: "#000000",
      neutral: {
        100: "#f5f5f5",
        200: "#e4e4e4",
        300: "#cccccc",
        400: "#949494",
        500: "#707070",
        600: "#616161",
        700: "#515151",
        800: "#353535",
        900: "#1a1a1a",
      },
      red: {
        100: "#fdecec",
        200: "#fac2c2",
        300: "#f69898",
        400: "#f26d6d",
        500: "#ef4444",
        600: "#DD1313",
        700: "#b20f0f",
        800: "#7f0b0b",
        900: "#4b0606",
      },
    },
    fontSize: {
      10: "10px",
      12: "12px",
      14: "14px",
      16: "16px",
      18: "18px",
      20: "20px",
      24: "24px",
      28: "28px",
      32: "32px",
      40: "40px",
      52: "52px",
      64: "64px",
    },
    lineHeight: {
      none: 1,
      tight: 1.25,
      snug: 1.4,
      normal: 1.618,
      relaxed: 1.75,
      loose: 2,
    },
    letterSpacing: {
      normal: "0",
      wide: ".08em",
      wider: ".2em",
    },
    extend: {
      cursor: {
        play: "url(/cursor-play.svg) 36 36, auto",
        pan: "url(/cursor-pan.svg) 36 36, auto",
        zoom: "url(/cursor-zoom.svg) 36 36, auto",
      },
      spacing: {
        12.5: "50px",
        18: "72px",
        22: "88px",
        26: "104px",
        30: "120px",
        34: "136px",
        39: "156px",
        46: "184px",
        52: "208px",

        "2xs": "var(--sp-2xs)",
        xs: "var(--sp-xs)",
        sm: "var(--sp-sm)",
        md: "var(--sp-md)",
        lg: "var(--sp-lg)",
        xl: "var(--sp-xl)",
        "2xl": "var(--sp-2xl)",
        "3xl": "var(--sp-3xl)",
        "4xl": "var(--sp-4xl)",
        "5xl": "var(--sp-5xl)",
        "6xl": "var(--sp-6xl)",
      },
      textColor: {
        skin: {
          base: "var(--color-text-base)",
          muted: "var(--color-text-muted)",
          inverted: "var(--color-text-inverted)",
          disabled: "var(--color-text-disabled)",
          danger: "var(--color-text-danger)",
        },
      },

      borderColor: {
        skin: {
          base: "var(--color-border-base)",
          muted: "var(--color-border-muted)",
          inverted: "var(--color-border-inverted)",
          danger: "var(--color-border-danger)",
        },
      },
      backgroundColor: {
        skin: {
          base: "var(--color-fill-base)",
          muted: "var(--color-fill-muted)",
          inverted: "var(--color-fill-inverted)",
          danger: "var(--color-fill-danger)",
        },
      },
      backgroundImage: {
        "checkbox-active":
          "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMTYuMjY5NSA1LjYwMDFMNy45NTcwMyAxNC45MTIyTDMuODAwNzggMTAuNzU2MyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==')",
        "radio-active":
          "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMTYuMjY5NSA1LjYwMDFMNy45NTcwMyAxNC45MTIyTDMuODAwNzggMTAuNzU2MyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==')",
        "angle-down":
          "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik00LjUgNi43IDggMTAuMmwzLjUtMy41IiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMS4yNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=')",
      },
      lineClamp: {
        8: "8",
      },
      transitionProperty: {
        enter: "transform, opacity",
      },
      transitionDuration: {
        none: "0ms",
        0: "0ms",
        250: "250ms",
        400: "400ms",
        1200: "1200ms",
        1500: "1500ms",
        2000: "2000ms",
      },
    },
  },
  plugins: [require("tailwindcss-animate")],
};
