import {
  ref,
  watch,
  useContext,
  computed,
  useRoute,
} from "@nuxtjs/composition-api";
import {
  useMediaQuery,
  templateRef,
  onClickOutside,
  useElementSize,
  useClipboard,
} from "@vueuse/core";
import { useCdn } from "/composables/useCdn";
import { useDataLayer } from "/composables/useDataLayer.js";


export default {
  __name: 'index',
  props: {
  title: {
    type: String,
    required: true,
  },
  slug: {
    type: String,
    required: true,
  },
  ambient: {
    type: Object,
    required: false,
  },
  initialSlide: {
    type: String,
    required: false,
    validator: function (value) {
      return (
        ["user-preference", "ambient", "first-variant"].indexOf(value) !== -1
      );
    },
    default: "ambient",
  },
  sizes: {
    type: Object,
    required: false,
  },
  loading: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "lazy", "eager"].indexOf(value) !== -1;
    },
    default: "auto",
  },
  variants: {
    type: Array,
    required: false,
  },
  materials: {
    type: Array,
    required: false,
  },
  to: {
    type: String,
    required: true,
  },
  useMaterials: {
    type: Boolean,
    required: false,
    default: false,
  },
  useMoodboard: {
    type: Boolean,
    required: false,
    default: true,
  },
  useAmbientAsInitial: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const route = useRoute();
const { getSrcset } = useCdn();
const { pushDataLayer } = useDataLayer();

const source = computed(() => {
  return process.client
    ? `${window.location.origin}/${route.value.params.locale}${props.to}`
    : "";
});
const { text, copy, copied, isSupported } = useClipboard({ source });



const { store } = useContext();
const tpl_carousel = templateRef("tpl_carousel");
const tpl_contextMenu = templateRef("tpl_contextMenu");
const swiperCarousel = ref(null);
const slidePrevEnabled = ref(false);
const slideNextEnabled = ref(false);
const showControls = ref(false);
const showMarkup = ref(false);
const contextMenuData = ref();
const browserHasHover = useMediaQuery("(hover: hover) and (pointer: fine)");

const ambientImgSources = computed(() => {
  let sources;

  if (props.ambient.pic.cdn && props.sizes) {
    const srcset = getSrcset(props.ambient.pic.src, props.sizes);
    sources = `src="${srcset.base}" srcset="${srcset.srcset}" sizes="${srcset.sizes}"`;
  } else {
    sources = `src="${props.ambient.pic.src}"`;
  }

  return sources;
});

const { width: carouselW, height: carouselH } = useElementSize(tpl_carousel);
const { width: contextMenuW } = useElementSize(tpl_contextMenu);

function handleContextMenu(event, x, y) {
  contextMenuData.value = {
    x:
      x + contextMenuW.value > carouselW.value
        ? carouselW.value - contextMenuW.value - 10
        : x,
    y,
  };
}

function addAmbientImage() {
  const tpl = `<div class="swiper-slide overflow-hidden aspect-3/2 bg-black">
    <img 
      ${ambientImgSources.value}" 
      alt="${props.ambient.pic.alt}" 
      class="w-full h-full object-cover" 
      loading="${props.loading}" 
      fetchpriority="${props.loading === "lazy" ? "auto" : "high"}" 
      data-swiper-parallax="55%" 
      data-swiper-parallax-opacity="0.35" 
    />
  </div>`;

  /* ADD AMBIENT <IMG> ACCORDING TO THE "initialSlide" PROP */

  swiperCarousel.value.addSlide(
    props.initialSlide === "ambient" ||
      (props.initialSlide === "user-preference" &&
        store.state.productCardUseAmbientAsInitial === true)
      ? 0
      : props.variants.length,
    tpl
  );
  /* end ADD AMBIENT <IMG> ACCORDING TO THE "initialSlide" PROP */

  swiperCarousel.value.slideTo(0, 0, false);
  swiperCarousel.value.update();
}

/* WATCH FOR INITIAL SLIDE UPDATE */
if (props.initialSlide === "user-preference") {
  watch(
    () => store.state.productCardUseAmbientAsInitial,
    (newVal, oldVal) => {
      if (showMarkup.value && swiperCarousel.value) {
        swiperCarousel.value.removeSlide(newVal ? props.variants.length : 0);
        addAmbientImage();
      }
    }
  );
}
/* end WATCH FOR INITIAL SLIDE UPDATE */

function checkControls() {
  if (browserHasHover.value) {
    slidePrevEnabled.value = swiperCarousel.value.realIndex > 0;
    slideNextEnabled.value =
      swiperCarousel.value.realIndex <
      (props.ambient ? props.variants.length : props.variants.length - 1);
  }
}

function isInViewport() {
  showMarkup.value = true;

  setTimeout(() => {
    if (!tpl_carousel.value) {
      return;
    }

    swiperCarousel.value = new Swiper(tpl_carousel.value, {
      slidesPerView: 1,
      roundLengths: true,
      threshold: 3,
      speed: 600,
      parallax: true,
      pagination: {
        el: tpl_carousel.value.getElementsByClassName("swiper-pagination")[0],
        type: "bullets",
      },
      on: {
        slideChange: function (swiper) {
          checkControls();
        },
      },
    });

    addAmbientImage();
    checkControls();
  }, Math.random() * 100);
}

function hasFullyExitViewport() {
  showMarkup.value = false;
  if (swiperCarousel.value) {
    swiperCarousel.value.destroy();
    swiperCarousel.value = null;
  }
}

watch(copied, (newVal, oldVal) => {
  if (newVal === false) contextMenuData.value = null;
});

onClickOutside(tpl_contextMenu, (event) => {
  contextMenuData.value = null;
});

return { __sfc: true,route, getSrcset, pushDataLayer, source, text, copy, copied, isSupported, props, store, tpl_carousel, tpl_contextMenu, swiperCarousel, slidePrevEnabled, slideNextEnabled, showControls, showMarkup, contextMenuData, browserHasHover, ambientImgSources, carouselW, carouselH, contextMenuW, handleContextMenu, addAmbientImage, checkControls, isInViewport, hasFullyExitViewport }
}

}