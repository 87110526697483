import { ref, computed, watch } from "vue";
import { useContext } from "@nuxtjs/composition-api";
import axios from "axios";

const state = ref({});

const vModel = ref({});

const CancelToken = axios.CancelToken;
let source;

export const useFiltersToolbar = () => {
  const { route, store } = useContext();

  state.value = {
    loading: false,
    loadingMore: false,
    filters: null,
    filtersQuery: "",
    results: [],
    total: 0,
    filtersInteractive: true,
    vModel: {},
  };

  vModel.value = store.state.headerToolbar.formValues;

  watch(vModel, (val, old) => {
    if (JSON.stringify(val) != JSON.stringify(old)) {
      updateFilters();
    }
  });

  function updateFiltersModel(data) {
    vModel.value = data ? data : {};
    updateFilters();
  }

  function resetToolbarFacets() {
    store.commit("headerToolbar/resetToolbarFacets");
  }

  function setFiltersInteractive(val) {
    state.value.filtersInteractive = val;
  }

  function updateFilters() {
    /* 
      Called at the submit of the filter form, after the model changed, it updates the filters query string
    */

    let model = JSON.parse(JSON.stringify(vModel.value));
    let query = "";
    let filters = null;
    for (let p in model) {
      if (model[p].length) {
        query += `${query.length ? "&" : ""}${p}=`;

        if (!filters) {
          filters = {};
        }
        filters[p] = model[p];
      }
      query += model[p].join(",");
    }
    state.value.filters = filters;
    state.value.filtersQuery = query;
  }

  async function loadMore(path, offset, itemsPerPage) {
    state.value.loadingMore = true;
    const filters = state.value.filtersQuery
      ? `&${state.value.filtersQuery}`
      : "";

    // cancel the request
    if (source) {
      source.cancel("Operation canceled by the user.");
      source = null;
    }

    source = CancelToken.source();
    const { data } = await axios.get(
      `${store.state.wsPath}v2/${path}?lng=${route.value.params.locale}${filters}&items_per_page=${itemsPerPage}&offset=${offset}`,
      { cancelToken: source.token }
    );

    state.value.results = state.value.results.concat(data.items);

    state.value.total = data.total;
    state.value.loadingMore = false;
    return state.value.results;
  }

  async function loadFilteredItems(path, offset = 0, itemsPerPage = 60) {
    /* 
        Once the filter query has been updated, load the items grid
    */

    state.value.results = [];
    if (state.value.filtersQuery) {
      // cancel the request
      if (source) {
        source.cancel("Operation canceled by the user.");
        source = null;
      }

      state.value.loading = true;

      source = CancelToken.source();
      const { data } = await axios.get(
        `${store.state.wsPath}v2/${path}?lng=${route.value.params.locale}&${state.value.filtersQuery}&items_per_page=${itemsPerPage}&offset=${offset}`,
        { cancelToken: source.token }
      );

      store.commit("headerToolbar/setToolbarFacets", data.facets);

      state.value.results = data.items;
      state.value.total = data.total;
      state.value.loading = false;
      return state.value.results;
    }

    return state.value.results;
  }

  return {
    loadMore,
    loadFilteredItems,
    updateFilters,
    updateFiltersModel,
    resetToolbarFacets,
    setFiltersInteractive,
    vModel,
    filtersInteractive: computed(
      () => state.value.filtersInteractive && !state.value.loading
    ),
    filters: computed(() => state.value.filters),
    loading: computed(() => state.value.loading),
    results: computed(() => state.value.results),
    total: computed(() => state.value.total),
    filtersQuery: computed(() => state.value.filtersQuery),
    toolbarFacets: computed(() => {
      const obj = {};
      for (let type in store.state.headerToolbar.toolbarFacets) {
        obj[type] = {};
        for (let facet in store.state.headerToolbar.toolbarFacets[type]) {
          const f = store.state.headerToolbar.toolbarFacets[type][facet];
          if (f.total > 0) {
            obj[type][facet] = `${f.label} (${f.total})`;
          }
        }
      }

      return obj; //store.state.headerToolbar.toolbarFacets;
    }),
  };
};
