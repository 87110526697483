import { ref, computed, watch } from "vue";
import { useContext } from "@nuxtjs/composition-api";
import { useDataLayer } from "/composables/useDataLayer.js";
import { useLogger } from "/composables/useLogger.js";
import find from "lodash/find";
import axios from "axios";

const state = ref({
  products: [],
  cart: null,
  totals: null,
  cartKey: null,
  updating: false,
  loading: false,
});

export const useCommerce = () => {
  const { store, $CoCartV1 } = useContext();
  const { pushDataLayer } = useDataLayer();
  const { log } = useLogger();

  watch(
    () => state.value.cartKey,
    () => {
      if (store.state.user.cart_key) {
        return;
      }

      axios
        .get(
          `${store.state.wsPath}v1/cart-key?ck=${state.value.cartKey}&user_id=${store.state.user.id}&token=${store.state.user.token}`
        )
        .then(() => {
          axios
            .post(
              `${store.state.wsPath}v2/form_validate`,
              JSON.stringify({
                token: store.state.user.token,
                id: store.state.user.id,
                lng: store.state.lang,
              })
            )
            .then(({ data }) => {
              if (data.code && data.code == "jwt_auth_valid_token") {
                store.commit("user/setUser", data.user);
              }
            });
        });
    }
  );

  async function updateItemInCart(cart_item_key, quantity) {
    const itemOld = state.value.cart.items.find(
      (item) => item.key == cart_item_key
    );

    var data = {
      cart_item_key: cart_item_key,
      quantity: quantity,
    };

    state.value.loading = true;
    const response = await $CoCartV1
      .post(`item?cart_key=${state.value.cartKey}`, data)
      .then((response) => {
        // Successful request
        state.value.loading = false;

        return response;
      })
      .catch((error) => {
        // Invalid request, for 4xx and 5xx statuses
        state.value.loading = false;
        console.log("Response Status:", error);
      });

    const status = response.status;
    return await getCart().then((response) => {
      if (status == 200) {
        if (quantity > itemOld.quantity) {
          sendDataLayer(
            "add_to_cart",
            state.value.cart.items.find((item) => item.key == cart_item_key)
          );
          log({
            data: state.value.cart,
            filename: "add-to-cart",
            folder: store.state.user.email,
          });
        } else {
          sendDataLayer("remove_from_cart", itemOld);
          log({
            data: state.value.cart,
            filename: "remove-from-cart",
            folder: store.state.user.email,
          });
        }
      }
      return response;
    });
  }

  async function addToCart(product) {
    const cartItemData = find(product.meta_data, {
      key: "custom_attributes",
    }).value;

    const options = {
      product_id: product.id.toString(),
      quantity: "1",
      cart_item_data: cartItemData,
    };

    state.value.loading = true;

    const response = await $CoCartV1
      .post(
        `add-item${
          state.value.cartKey ? `?cart_key=${state.value.cartKey}` : ""
        }`,
        options
      )
      .catch((error) => {
        console.log("cart/add-item > error:", error);
      });

    state.value.loading = false;

    const status = response?.status;
    return await getCart().then((response) => {
      if (status == 200) {
        sendDataLayer(
          "add_to_cart",
          state.value.cart.items.find((item) => item.product_id == product.id)
        );
        log({
          data: state.value.cart,
          filename: "add-to-cart",
          folder: store.state.user.email,
        });
      }
      return response;
    });
  }

  function sendDataLayer(event, item) {
    if (!item) {
      return;
    }
    const data = {
      event: event,
      ecommerce: {
        currency: "EUR",
        value: item.line_price,
        items: [
          {
            item_id: item.sku,
            item_name: item.product_title,
            item_category: item.material_name,
            item_category2: item.parent_collection,
            item_list_name: item.parent_name,
            price: item.price,
            quantity: item.quantity,
          },
        ],
      },
    };
    pushDataLayer(data);
  }

  async function getCoupon() {
    state.value.loading = true;
    const response = await $CoCartV1
      .get(
        `coupon${state.value.cartKey ? `?cart_key=${state.value.cartKey}` : ""}`
      )
      .catch((error) => {
        console.log("cart/coupon > error:", error);
      });
    state.value.loading = false;
    return await getCart();
  }

  async function addCoupon(coupon) {
    state.value.loading = true;
    const response = await $CoCartV1
      .post(
        `coupon${
          state.value.cartKey ? `?cart_key=${state.value.cartKey}` : ""
        }`,
        {
          coupon: coupon,
        }
      )
      .catch((error) => {
        console.log(error);
        throw new Error(error);
      });

    state.value.loading = false;

    if (response?.status == 200) {
      return await getCart();
    }
  }

  async function removeCoupon(coupon) {
    state.value.loading = true;
    const response = await fetch(
      `/sm/api/cocart/remove-coupon/${state.value.cartKey}/${coupon}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          wpPath: store.state.wpPath,
        }),
      }
    );
    const data = await response.json();
    state.value.loading = false;
    return await getCart();
  }

  async function removeFromCart(itemKey) {
    state.value.loading = true;

    sendDataLayer(
      "remove_from_cart",
      state.value.cart.items.find((item) => item.key == itemKey)
    );

    log({
      data: state.value.cart,
      filename: "remove-from-cart",
      folder: store.state.user.email,
    });

    const response = await fetch(
      `/sm/api/cocart/remove-item/${state.value.cartKey}/${itemKey}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          wpPath: store.state.wpPath,
        }),
      }
    );
    const data = await response.json();
    state.value.cart = data;
    state.value.loading = false;

    return data;
  }

  function initCartKey() {
    if (!state.value.cartKey) {
      state.value.cartKey =
        store.state.user.cart_key ||
        [...Array(42)]
          .map((i) => (~~(Math.random() * 36)).toString(36))
          .join("");
    }
  }

  function getCart() {
    initCartKey();
    state.value.loading = true;
    return $CoCartV1
      .get(
        `get-cart${
          state.value.cartKey ? `?cart_key=${state.value.cartKey}` : ""
        }`
      )
      .then((response) => {
        state.value.cart = response.data;
        state.value.loading = false;
        return response;
      })
      .catch((error) => {
        state.value.loading = false;
        return error;
      });
  }

  function updateShipping() {
    initCartKey();
    state.value.loading = true;
    return $CoCartV1
      .post(`calculate/shipping?cart_key=${state.value.cartKey}`, {
        country: store.state.user.shopping_address.shipping_country,
        // postcode: store.state.user.shopping_address.shipping_zip_code,
        // state: store.state.user.shopping_address.shipping_province,
      })
      .then((response) => {
        state.value.loading = false;
        return response;
      })
      .catch((error) => {
        state.value.loading = false;
        return error;
      });
  }

  function getProductsBySkus(skus) {
    state.value.loading = true;
    return $CoCartV1
      .get(`products?sku=${skus.join(",")}`)
      .then(({ data }) => {
        // Successful request
        state.value.products = data;
        state.value.loading = false;
        return data;
      })
      .catch((error) => {
        // Invalid request, for 4xx and 5xx statuses
        state.value.loading = false;
        console.log("products > Response Data:", error.response.data);
      });
  }

  function calculateTotals() {
    state.value.loading = true;
    return $CoCartV1
      .get(
        `totals${state.value.cartKey ? `?cart_key=${state.value.cartKey}` : ""}`
      )
      .then((response) => {
        // Successful request
        state.value.totals = response.data;
        state.value.loading = false;
        return response;
      })
      .catch((error) => {
        // Invalid request, for 4xx and 5xx statuses
        state.value.loading = false;
      });
  }

  function clearCart() {
    state.value.loading = true;
    return $CoCartV1
      .post("clear")
      .then((response) => {
        // Successful request
        state.value.cart = null;
        state.value.cartKey = null;
        state.value.loading = false;
        return response;
      })
      .catch((error) => {
        state.value.loading = false;
      })
      .finally(() => {
        state.value.loading = false;
        // Always executed.
        axios.get(
          `${store.state.wsPath}v1/cart-key?ck=&user_id=${store.state.user.id}&token=${store.state.user.token}`
        );
      });
  }

  function resetLocalState() {
    state.value = {
      products: [],
      cart: null,
      cartKey: null,
      updating: false,
      loading: false,
    };
  }

  async function createOrder(payload) {
    const response = await axios.post(
      `${store.state.wsPath}v2/form_create-order`,
      payload
    );
    return response;
  }

  return {
    products: computed(() => state.value.products),
    cart: computed(() => state.value.cart),
    updating: computed(() => state.value.updating),
    loading: computed(() => state.value.loading),
    itemsFromLatest: computed(() => {
      return state.value.cart?.items
        ? [...state.value.cart.items.reverse()]
        : [];
    }),
    orders: computed(() => state.value.orders),
    totals: computed(() => state.value.totals),
    createOrder,
    addToCart,
    getCart,
    updateShipping,
    getProductsBySkus,
    updateItemInCart,
    calculateTotals,
    clearCart,
    removeFromCart,
    getCoupon,
    addCoupon,
    removeCoupon,
    resetLocalState,
  };
};
