import { useContext } from "@nuxtjs/composition-api";
import useTailwindCss from "/composables/useTailwindCss";
import last from "lodash/last";

export const useCdn = () => {
  const { store, $cloudinary } = useContext();
  const { screens } = useTailwindCss();

  function fromCloudinary(url, mq, params) {
    if (!url) {
      return "";
    }

    url = url.split("?")[0];

    const folder = /on-page/.test(url) ? "on-page" : "glamora";

    const width = typeof mq == "number" ? mq : mq.width;

    let format =
      store.state.supportsAvif && width && width < 3000 ? "avif" : "auto";

    if (params?.attachment) {
      format = "jpg";
    } else if (params?.format) {
      format = params.format;
    }

    const options = {
      crop: mq.ar ? "fill" : "limit",
      width: width,
      quality: params?.quality ? params.quality : "auto:eco",
      fetchFormat: format,
    };

    if (mq.ar) {
      options.aspectRatio = mq.ar;
    }

    if (mq.height) {
      options.height = mq.height;
    }

    if (mq.crop) {
      options.crop = mq.crop;
    }

    if (url.match("private")) {
      options.type = "private";
    }

    if (params?.attachment) {
      options.flags = "attachment";
    }

    let img = $cloudinary.image.url(
      `${folder}/${last(url.split("/"))}`,
      options
    );

    if (params?.attachment) {
      if (typeof params.attachment == "string") {
        img = `${store.state.wpPath}/download-image.php?url=${img}&name=${params.attachment}`;
      }
    }

    return img;
  }

  function getSrcset(url, queries) {
    const orderedScreens = [];
    const orderedQueries = ["sm", "md", "lg", "xl", "2xl"];

    for (let i = 0; i < orderedQueries.length; i++) {
      orderedScreens.push({
        prop: orderedQueries[i],
        value: parseInt(screens[orderedQueries[i]].min.replace("px", "")),
      });
    }
    const srcset = [];
    const sizes = [];

    for (let i = 0; i < orderedScreens.length; i++) {
      if (queries[orderedScreens[i].prop]) {
        srcset.push(
          `${fromCloudinary(url, queries[orderedScreens[i].prop])} ${
            orderedScreens[i].value
          }w`
        );

        sizes.push(
          `(min-width: ${orderedScreens[i].value}px) ${
            queries[orderedScreens[i].prop]
          }w`
        );
      }
    }

    return {
      base: fromCloudinary(url, queries.base),
      srcset: srcset.join(", "),
      sizes: sizes.join(", "),
    };
  }

  return {
    getSrcset,
    fromCloudinary,
  };
};
