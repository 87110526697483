import { computed, useContext } from "@nuxtjs/composition-api";

export const useCountries = () => {
  const { store } = useContext();

  const countryData = [...store.state.geolist.countries].sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const normalizedIntlPhonePrefixes = [];
  for (let i = 0; i < store.state.geolist.phones.length; i++) {
    normalizedIntlPhonePrefixes.push({
      value: store.state.geolist.phones[i].isoCode,
      label: store.state.geolist.phones[i].label,
      prefix: store.state.geolist.phones[i].prefixNumber,
    });
  }

  const intlPhonePrefixes = normalizedIntlPhonePrefixes.sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const statesUSData = store.state.geolist.states.US;

  const regionsESData = store.state.geolist.regions.ES;

  const provincesITData = store.state.geolist.provinces.IT.alphabetical;

  const provincesESData = store.state.geolist.provinces.ES.alphabetical;

  return {
    countries: computed(() => countryData),
    intlPhonePrefixes,
    statesUS: computed(() => statesUSData),
    regionsES: computed(() => regionsESData),
    provincesIT: computed(() => provincesITData),
    provincesES: computed(() => provincesESData),
    storeLocatorCountries: computed(
      () => store.state.geolist.store_locator.countries
    ),
    storeLocatorRegionsIT: computed(
      () => store.state.geolist.store_locator.regions.it
    ),
    storeLocatorStatesDE: computed(
      () => store.state.geolist.store_locator.regions.de
    ),
    storeLocatorAutonomousCommunitiesES: computed(
      () => store.state.geolist.store_locator.regions.es
    ),
  };
};
