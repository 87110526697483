import { ref } from "vue";
import { useUser } from "/composables/useUser";
import { useHeaderToolbar } from "@/composables/useHeaderToolbar";

export default {
  __name: 'index',
  setup(__props) {

const { toolbarData } = useHeaderToolbar();

const { logged } = useUser();
const showModal = ref(false);
function checkUser(event) {
  if (!logged.value) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();

    showModal.value = true;
  }
}

return { __sfc: true,toolbarData, logged, showModal, checkUser }
}

}