import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
//import { ScrollSmoother } from "gsap/ScrollSmoother";
//import { SplitText } from "gsap/SplitText";
import { Draggable } from "gsap/Draggable";
//import { Flip } from "gsap/Flip";
import { InertiaPlugin } from "gsap/InertiaPlugin";

if (process.client) {
  gsap.registerPlugin(
    ScrollToPlugin,
    ScrollTrigger,
    //ScrollSmoother,
    //SplitText,
    //Flip,
    Draggable,
    InertiaPlugin
  );
  window.gsap = gsap;
  window.ScrollTrigger = ScrollTrigger;
  //window.ScrollSmoother = ScrollSmoother;
  //window.Flip = Flip;
  //window.SplitText = SplitText;
  window.Draggable = Draggable;
}
