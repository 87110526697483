export const state = () => ({
  type: null,
  toolbarFacetsOriginal: null,
  toolbarFacets: null,
  data: {
    updateTime: 1,
  },
  formValues: {},
});

export const mutations = {
  setToolbar(state, payload) {
    state.type = payload;
  },
  setFormValues(state, payload) {
    state.formValues = payload;
  },
  setToolbarFacets(state, payload) {
    /* for (let p1 in payload.color) {
      for (let p2 in payload.color) {
        if (p1 != p2) {
          if (
            payload.color[p1].label.toLowerCase().charAt(0) <
            payload.color[p2].label.toLowerCase().charAt(0)
          ) {
            let temp = payload.color[p1];
            payload.color[p1] = payload.color[p2];
            payload.color[p2] = temp;
          }
        }
      }
    } */

    state.toolbarFacets = payload;
    if (!state.toolbarFacetsOriginal) {
      state.toolbarFacetsOriginal = payload;
    }
  },
  disposeToolbarFacets(state, payload) {
    state.formValues = {};
    state.toolbarFacets = null;
    state.toolbarFacetsOriginal = null;
  },
  resetToolbarFacets(state) {
    state.toolbarFacets = state.toolbarFacetsOriginal;
  },
  setDataByRouteName(state, payload) {
    state.data[payload.routeName] = payload.data;
    state.data.updateTime = Date.now();
  },
};

export const actions = {};

export const getters = {
  loadedToolbar(state) {
    return state.type;
  },
};
