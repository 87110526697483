import axios from "axios";
import map from "lodash/map";
import find from "lodash/find";

export default async ({ route, store, redirect, req, $cookies }) => {
  const host = req ? req.headers.host : window.location.host.split(":")[0];

  if (process.server) {
    store.commit(
      "setIsIOS",
      /iPhone|iPad|iPod/.test(req.headers["user-agent"])
    );

    store.commit("setIsIPhone", /iPhone/.test(req.headers["user-agent"]));
  }

  /*  */
  if (route.path.includes("professional-consulting")) {
    redirect(
      `/${
        route.params.locale ? route.params.locale : ""
      }/contacts/customer-care-service`
    );
    return;
  }

  // if !development
  if (process.env.NODE_ENV !== "development" && !host.includes("staging")) {
    if (route.path.includes("contacts/become-a-partner")) {
      redirect(
        `/${
          route.params.locale ? route.params.locale : ""
        }/contacts/customer-care-service`
      );
      return;
    }
  }

  /*  */

  const AUTH_ROUTES = [
    { name: "locale-account-moodboards", redirect: "signup" },
    { name: "locale-account-personal-info", redirect: "signup" },
    { name: "locale-account-orders", redirect: "signup" },
    { name: "locale-cart", redirect: "signup" },
    { name: "locale-account-shopping-address", redirect: "signup" },
    { name: "locale-checkout", redirect: "signup" },
    { name: "locale-checkout-thank-you", redirect: "signup" },
  ];

  function getRedirectURL() {
    if (!route.name) {
      return "";
    }
    let url =
      find(AUTH_ROUTES, (el) => new RegExp(`^${route.name}$`).test(el.name))
        ?.redirect || "";
    return url ? `/${route.params.locale}/${url}` : "";
  }

  const token = $cookies.get("glmr_token");
  const id = $cookies.get("glmr_id");

  if (
    /* !store.state.fromName || */
    new RegExp(`${map(AUTH_ROUTES, "name").join("|")}`).test(route.name)
  ) {
    if (!token || !id) {
      return redirect(getRedirectURL());
    }

    const { data } = await axios
      .post(
        `${store.state.wsPath}v2/form_validate`,
        JSON.stringify({
          token,
          id,
          lng: route.params.locale,
        })
      )
      .catch(function (error) {
        redirect(getRedirectURL());
      });

    if (data.data.status == 200) {
      if (
        (route.name == "locale-account-shopping-address" &&
          !data.user?.has_address == "1") ||
        (route.name == "locale-account-orders" && !data.user?.has_orders == "1")
      ) {
        redirect("/");
      }

      saveUser(data);
    }
  } else {
    return;
  }

  function saveUser(data) {
    if (data.code && data.code == "jwt_auth_valid_token") {
      store.commit("user/setUser", data.user);
    } else {
      store.commit("user/setUser", null);
      redirect(getRedirectURL());
    }
  }
};
