import { computed } from "vue";

export default {
  __name: 'index',
  props: {
  type: {
    type: String,
    required: false,
    validator: function (value) {
      return (
        [
          "text",
          "textarea",
          "email",
          "hidden",
          "password",
          "number",
          "tel",
          "url",
        ].indexOf(value) !== -1
      );
    },
    default: "text",
  },
  name: {
    type: String,
    required: true,
  },
  inputKey: {
    type: String,
    required: false,
  },
  label: {
    type: String,
    required: false,
  },
  placeholder: {
    type: String,
    required: false,
  },
  help: {
    type: String,
    required: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  validation: {
    type: [String, Array],
    required: false,
  },
  validationRules: {
    type: Object,
    required: false,
  },
  validationMessages: {
    type: Object,
    required: false,
  },
  icon: {
    type: String,
    required: false,
  },
  changeCallback: {
    type: Function,
    required: false,
    default: function (event) {},
  },
  autocomplete: {
    type: Boolean,
    required: false,
    default: false,
  },
  autofill: {
    type: Boolean,
    required: false,
    default: false,
  },
  autocorrect: {
    type: Boolean,
    required: false,
    default: false,
  },
  autocapitalize: {
    type: Boolean,
    required: false,
    default: false,
  },
  maxlength: {
    type: Number,
    required: false,
  },
},
  setup(__props) {

const props = __props;



const labelText = computed(() => {
  let s = "";

  if (props.label) {
    s = props.label;
  }

  if (props.label && props.validation && props.validation.match("required")) {
    s += "*";
  }

  return s;
});

const inputClass = computed(() => {
  const base = `appearance-none rounded-none px-md border disabled:!pointer-events-none w-full text-body-big ${
    props.type !== "textarea" ? "leading-snug" : ""
  }`;

  const size =
    props.type === "textarea" ? "h-40 min-h-[80px] max-h-[60vh] py-xs" : "h-18";

  const colors =
    "border-skin-muted text-skin-base placeholder:text-skin-muted bg-skin-base";
  const interactive = props.disabled
    ? "disabled:!text-skin-base disabled:!border-skin-muted disabled:!bg-skin-muted"
    : "hover:text-skin-base hover:border-skin-base valid:text-skin-base valid:border-skin-base invalid:text-skin-danger invalid:border-skin-danger focus:!outline-none focus:ring-transparent focus:text-skin-base focus:border-current";
  const transition = "transition-colors duration-300 ease-in-out";

  return `${base} ${size} ${colors} ${interactive} ${transition}`;
});

const wrapperClass = computed(() => {
  const base = "relative top-0 left-0 inline-flex flex-col gap-y-2xs w-full";
  const interactive = props.disabled ? "!pointer-events-none" : "";

  return `${base} ${interactive}`;
});

const labelClass = computed(() => {
  const base = `relative z-10 block text-skin-base text-body-small truncate`;
  const interactive = props.disabled ? `!text-skin-muted` : ``;

  return `${base} ${interactive}`;
});

return { __sfc: true,props, labelText, inputClass, wrapperClass, labelClass }
}

}