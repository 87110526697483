export default {
  __name: 'index',
  props: {
  items: {
    type: Array,
    required: true,
  },
  title: {
    type: String,
    required: false,
  },
},
  setup(__props) {

const props = __props;



function figureAspectRatio(aspect) {
  let s = "w-full";
  switch (aspect) {
    case "3/2":
      s += " aspect-3/2";
      break;

    case "4/5":
      s += " aspect-4/5";
      break;
  }

  return s;
}

return { __sfc: true,props, figureAspectRatio }
}

}