import axios from "axios";

export default ({ store, route, error }, inject) => {
  async function getAPI(url, params = {}, preview) {
    if (!params) {
      params = {};
    }

    params = Object.assign(params, {
      lng: route.params.locale,
    });

    if (preview) {
      params = Object.assign(params, {
        email: store.state.user.email,
        token: store.state.user.token,
        preview: true,
        skip_cache: 1,
      });
    }

    try {
      const response = await axios.get(`${store.state.wsPath}v2/${url}`, {
        params: params,
      });

      return response;
    } catch (e) {
      if (preview) {
        return error({
          statusCode: 500,
          message:
            "You must be logged in with an administrator profile to access the preview function.",
        });
      }
    }
  }

  inject("getAPI", getAPI);
};
