import { ref } from "vue";
import { useUser } from "/composables/useUser";


export default {
  __name: 'index',
  props: {
  title: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: false,
  },
  to: {
    type: String,
    required: false,
  },
  download: {
    type: [String, Object],
    required: false,
  },
  needsUser: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props) {

const props = __props;



const { logged } = useUser();
const showModal = ref(false);
function checkUser(event) {
  if (props.needsUser && !logged.value) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();

    showModal.value = true;
  }
}

return { __sfc: true,props, logged, showModal, checkUser }
}

}