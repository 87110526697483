import { ref, onMounted } from "vue";
import { templateRef } from "@vueuse/core";


export default {
  __name: 'index',
  props: {
  visible: {
    type: Boolean,
    required: false,
    default: false,
  }
},
  setup(__props) {

const props = __props;



const tpl_carousel = templateRef("tpl_carousel");
const swiperCarousel = ref(null);

onMounted(()=> {
    swiperCarousel.value = new Swiper(tpl_carousel.value, {
      loop: true,
      allowTouchMove: false,
      slidesPerView: 2.7,
      speed: 600,
      spaceBetween: 16,
      autoplay: {
        delay: 1200
      }
    });
});

return { __sfc: true,props, tpl_carousel, swiperCarousel }
}

}