import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_90ef3a56 from 'nuxt_plugin_plugin_90ef3a56' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_plugin_e4aab006 from 'nuxt_plugin_plugin_e4aab006' // Source: ./composition-api/plugin.mjs (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_0b3fcf13 from 'nuxt_plugin_cookieuniversalnuxt_0b3fcf13' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_recaptcha_69b3734c from 'nuxt_plugin_recaptcha_69b3734c' // Source: ./recaptcha.js (mode: 'all')
import nuxt_plugin_portalvue_343f9c70 from 'nuxt_plugin_portalvue_343f9c70' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_axios_3ab0656b from 'nuxt_plugin_axios_3ab0656b' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_pluginclient_2d613b48 from 'nuxt_plugin_pluginclient_2d613b48' // Source: ./cloudinary/plugin.client.js (mode: 'client')
import nuxt_plugin_pluginserver_d9caa660 from 'nuxt_plugin_pluginserver_d9caa660' // Source: ./cloudinary/plugin.server.js (mode: 'server')
import nuxt_plugin_nuxtplugin88130a7e_4d772adc from 'nuxt_plugin_nuxtplugin88130a7e_4d772adc' // Source: ./nuxt.plugin.88130a7e.js (mode: 'all')
import nuxt_plugin_router_79dc5b58 from 'nuxt_plugin_router_79dc5b58' // Source: ../plugins/router.js (mode: 'all')
import nuxt_plugin_onnuxtready_2d6b0506 from 'nuxt_plugin_onnuxtready_2d6b0506' // Source: ../plugins/on-nuxt-ready.js (mode: 'client')
import nuxt_plugin_gsap_0d8e3474 from 'nuxt_plugin_gsap_0d8e3474' // Source: ../plugins/gsap.js (mode: 'all')
import nuxt_plugin_swiper_68e7f06e from 'nuxt_plugin_swiper_68e7f06e' // Source: ../plugins/swiper.js (mode: 'all')
import nuxt_plugin_viewportobserver_258e7530 from 'nuxt_plugin_viewportobserver_258e7530' // Source: ../plugins/viewport-observer.js (mode: 'all')
import nuxt_plugin_api_785206da from 'nuxt_plugin_api_785206da' // Source: ../plugins/api.js (mode: 'all')
import nuxt_plugin_cocartv1_1f959c91 from 'nuxt_plugin_cocartv1_1f959c91' // Source: ../plugins/cocart-v1.js (mode: 'all')
import nuxt_plugin_datadog_19a1b94a from 'nuxt_plugin_datadog_19a1b94a' // Source: ../plugins/datadog.js (mode: 'server')
import nuxt_plugin_meta_49265e4f from 'nuxt_plugin_meta_49265e4f' // Source: ./composition-api/meta.mjs (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"css":false,"mode":"out-in","beforeEnter":function(el) {
      this.$emit("before-enter", {
        el
      });
    },"enter":function(el, done) {
      this.$emit("enter", {
        el,
        done: done
      });
    },"afterEnter":function(el) {
      this.$emit("after-enter", {
        el
      });
    },"enterCancelled":function(el) {
      this.$emit("enter-cancelled", {
        el
      });
    },"beforeLeave":function(el) {
      this.$emit("before-leave", {
        el
      });
    },"leave":function(el, done) {
      this.$emit("leave", {
        el,
        done: done
      });
    },"afterLeave":function(el) {
      this.$emit("after-leave", {
        el
      });
    },"leaveCancelled":function(el) {
      this.$emit("leave-cancelled", {
        el
      });
    },"name":"page","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Glamora","htmlAttrs":{"lang":"en","dir":"ltr"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=5.0"},{"hid":"description","name":"description","content":""},{"name":"format-detection","content":"telephone=no"},{"name":"facebook-domain-verification","content":"ns4xtm7w5gpx53pruj5am6f78g9suc"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"script":[{"src":"https:\u002F\u002Fwww.googletagmanager.com\u002Fgtag\u002Fjs?id=UA-157017477-1","async":true},{"innerHTML":"window.dataLayer = window.dataLayer || [];\n          function gtag(){dataLayer.push(arguments);}\n          gtag('js', new Date());\n          gtag('config', 'UA-157017477-1');\n          gtag('config', 'UA-157017477-1', { 'anonymize_ip': true });"},{"id":"hs-script-loader","async":true,"defer":true,"src":"\u002F\u002Fjs.hs-scripts.com\u002F9038321.js"},{"innerHTML":"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n          'https:\u002F\u002Fwww.googletagmanager.com\u002Fgtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n          })(window,document,'script','dataLayer','GTM-T44ZW97');"},{"type":"application\u002Fld+json","innerHTML":"{\n          \"@context\": \"https:\u002F\u002Fschema.org\",\n          \"@type\": \"Organization\",\n          \"url\": \"https:\u002F\u002Fglamora.it\",\n          \"logo\": \"https:\u002F\u002Fglamora.it\u002Flogo.jpg\"\n        }"}],"noscript":[{"innerHTML":"\u003Ciframe src=\"https:\u002F\u002Fwww.googletagmanager.com\u002Fns.html?id=GTM-T44ZW97\";height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"\u003E\u003C\u002Fiframe\u003E","body":true}],"__dangerouslyDisableSanitizers":["script","noscript"],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_90ef3a56 === 'function') {
    await nuxt_plugin_plugin_90ef3a56(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_e4aab006 === 'function') {
    await nuxt_plugin_plugin_e4aab006(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_0b3fcf13 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_0b3fcf13(app.context, inject)
  }

  if (typeof nuxt_plugin_recaptcha_69b3734c === 'function') {
    await nuxt_plugin_recaptcha_69b3734c(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_343f9c70 === 'function') {
    await nuxt_plugin_portalvue_343f9c70(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3ab0656b === 'function') {
    await nuxt_plugin_axios_3ab0656b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginclient_2d613b48 === 'function') {
    await nuxt_plugin_pluginclient_2d613b48(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_pluginserver_d9caa660 === 'function') {
    await nuxt_plugin_pluginserver_d9caa660(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtplugin88130a7e_4d772adc === 'function') {
    await nuxt_plugin_nuxtplugin88130a7e_4d772adc(app.context, inject)
  }

  if (typeof nuxt_plugin_router_79dc5b58 === 'function') {
    await nuxt_plugin_router_79dc5b58(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_onnuxtready_2d6b0506 === 'function') {
    await nuxt_plugin_onnuxtready_2d6b0506(app.context, inject)
  }

  if (typeof nuxt_plugin_gsap_0d8e3474 === 'function') {
    await nuxt_plugin_gsap_0d8e3474(app.context, inject)
  }

  if (typeof nuxt_plugin_swiper_68e7f06e === 'function') {
    await nuxt_plugin_swiper_68e7f06e(app.context, inject)
  }

  if (typeof nuxt_plugin_viewportobserver_258e7530 === 'function') {
    await nuxt_plugin_viewportobserver_258e7530(app.context, inject)
  }

  if (typeof nuxt_plugin_api_785206da === 'function') {
    await nuxt_plugin_api_785206da(app.context, inject)
  }

  if (typeof nuxt_plugin_cocartv1_1f959c91 === 'function') {
    await nuxt_plugin_cocartv1_1f959c91(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_datadog_19a1b94a === 'function') {
    await nuxt_plugin_datadog_19a1b94a(app.context, inject)
  }

  if (typeof nuxt_plugin_meta_49265e4f === 'function') {
    await nuxt_plugin_meta_49265e4f(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
