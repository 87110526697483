import { ref } from "vue";
import { useUser } from "/composables/useUser";
import { useMoodboards } from "@/composables/useMoodboards";


export default {
  __name: 'index',
  props: {
  visible: {
    type: Boolean,
    required: false,
    default: false,
  },
  loginReason: {
    type: String,
    required: false,
    validator: function (value) {
      return ["action", "required"].indexOf(value) !== -1;
    },
    default: "required",
  },
},
  setup(__props) {

const props = __props;

const { logout, user } = useUser();
const { moodboards } = useMoodboards();
const submitState = ref(null);



async function handleUserLogout() {
  try {
    submitState.value = "submitting";
    const response = await logout();
    submitState.value = null;
  } catch (e) {
    formErrorsData.value = [store.state.labels.something_went_wrong];
    submitState.value = null;
  }
}

return { __sfc: true,logout, user, moodboards, submitState, props, handleUserLogout }
}

}