export const useLocalDate = () => {
  const options = {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  const noDate = "/ / /";

  function unixToLocalDay(unix_date) {
    if (unix_date)
      return new Date(unix_date * 1000).toLocaleString(undefined, {
        month: options.month,
        day: options.day,
        year: options.year,
      });
    else return noDate;
  }

  function unixToLocalMinute(unix_date) {
    if (unix_date)
      return new Date(unix_date * 1000).toLocaleString(undefined, {
        month: options.month,
        day: options.day,
        year: options.year,
        hour: options.hour,
        minute: options.minute,
      });
    else return noDate;
  }

  function unixToLocalSecond(unix_date) {
    if (unix_date)
      return new Date(unix_date * 1000).toLocaleString(undefined, {
        month: options.month,
        day: options.day,
        year: options.year,
        hour: options.hour,
        minute: options.minute,
        second: options.second,
      });
    else return noDate;
  }

  return {
    unixToLocalDay,
    unixToLocalMinute,
    unixToLocalSecond,
  };
};
