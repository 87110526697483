
export default {
  async asyncData({ $getAPI, store, route, error }) {
    try {
      const { data } = await $getAPI(
        "page_special-editions",
        null,
        route.query.preview
      );
      return {
        ...data,
      };
    } catch (e) {
      console.log(e);
      error({
        statusCode: 500,
        message: store.state.labels.error_generic,
      });
    }
  },
};
