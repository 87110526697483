import { render, staticRenderFns } from "./index.vue?vue&type=template&id=76c76a38"
import script from "./index.vue?vue&type=script&setup=true&lang=js"
export * from "./index.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VectorIcon: require('/var/www/html/glamoraV2/frontend/components/vector/icon/index.vue').default,Btn: require('/var/www/html/glamoraV2/frontend/components/btn/index.vue').default})
