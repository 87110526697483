import axios from "axios";
import Vue from "vue";

export const state = () => ({});

export const mutations = {
  setData(state, data) {
    for (let prop in data) {
      Vue.set(state, prop, data[prop]);
    }
  },
};

export const actions = {
  async load({ commit, rootState }, params) {
    const { data } = await axios.get(
      `${rootState.wsPath}v2/geolist?lng=${params.lang}`
    );
    commit("setData", data);
  },
};
