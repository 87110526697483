export default ({ app }) => {
  app.router.afterEach((to, from) => {
    if (process.client) {
      window.dispatchEvent(new Event("router_after_each"));
    }
  });

  app.router.beforeEach((to, from, next) => {
    next();
    if (process.client) {
      window.dispatchEvent(new Event("router_before_each"));
    }
  });
};
