export default {
  __name: 'index',
  props: {
  tag: {
    type: String,
    required: false,
    validator: function (value) {
      return ["div", "blockquote"].indexOf(value) !== -1;
    },
    default: "div",
  },
},
  setup(__props) {

const props = __props;



return { __sfc: true,props }
}

}