import { ref, onMounted } from "vue";
import { useIntersectionObserver, templateRef } from "@vueuse/core";
import { useCdn } from "/composables/useCdn";


export default {
  __name: 'index',
  props: {
  poster: {
    type: String,
    required: false,
  },
  usePoster: {
    type: Boolean,
    required: false,
    default: true
  },
  src: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  width: {
    type: [Number, String],
    required: false,
  },
  height: {
    type: [Number, String],
    required: false,
  },
  preload: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "metadata", "none"].indexOf(value) !== -1;
    },
    default: "auto",
  },
  replayMode: {
    type: String,
    required: false,
    validator: function (value) {
      return ["restart", "resume"].indexOf(value) !== -1;
    },
    default: "resume",
  },
  useIntersectionObserver: {
    type: Boolean,
    required: false,
    default: true,
  },
},
  setup(__props) {

const props = __props;


const showTitle = ref(false);
const tpl_root = templateRef("tpl_root");
const targetIsVisible = ref(false);

const { fromCloudinary } = useCdn();

const { stop } = useIntersectionObserver(
  tpl_root,
  ([{ isIntersecting }], observerElement) => {
    targetIsVisible.value = isIntersecting;
    if (props.useIntersectionObserver) handlePlayback();
  }
);

function handlePlayback() {
  if (targetIsVisible.value) {
    if (props.replayMode === "restart") tpl_root.value.currentTime = 0;
    tpl_root.value.play();
  } else tpl_root.value.pause();
}

onMounted(() => {
  props.useIntersectionObserver ? handlePlayback() : tpl_root.value.play();
});

return { __sfc: true,props, showTitle, tpl_root, targetIsVisible, fromCloudinary, stop, handlePlayback }
}

}