import { ref, onMounted, nextTick, watch } from "vue";
import { useMutationObserver } from "@vueuse/core";


export default {
  __name: 'index',
  props: {
  title: {
    type: String,
    required: false,
  },
},
  emits: ["update"],
  setup(__props, { emit }) {

const props = __props;





const isOpen = ref(false);
const tpl_root = ref(null);

useMutationObserver(
  tpl_root,
  (mutations) => {
    isOpen.value = mutations[0].oldValue === null;
  },
  {
    attributes: true,
    attributeFilter: ["open"],
    attributeOldValue: true,
  }
);

watch(isOpen, () => {
  ScrollTrigger.refresh(true);
  emit("update", isOpen.value);
});

onMounted(() => {
  nextTick(() => {
    nextTick(() => {
      isOpen.value = tpl_root.value.hasAttribute("open");
    });
  });
});

return { __sfc: true,props, emit, isOpen, tpl_root }
}

}