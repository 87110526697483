import { ref, watch } from "vue";
import { useUser } from "/composables/useUser";


export default {
  __name: 'index',
  props: {
  visible: {
    type: Boolean,
    required: false,
    default: false,
  },
  needsUser: {
    type: [Boolean, Function],
    required: false,
    default: false,
  },
  loginReason: {
    type: String,
    required: false,
    validator: function (value) {
      return ["action", "required"].indexOf(value) !== -1;
    },
    default: "required",
  },
  useClose: {
    type: Boolean,
    required: false,
    default: true,
  },
  constrainWidth: {
    type: Boolean,
    required: false,
    default: true,
  },
  labelledby: {
    type: String,
    required: true,
  },
  describedby: {
    type: String,
    required: false,
  },
  backdrop: {
    type: [Boolean, String],
    required: false,
    default: true,
  },
  transition: {
    type: String,
    required: false,
    validator: function (value) {
      return ["slide-from-right", "slide-from-bottom"].indexOf(value) !== -1;
    },
    default: "slide-from-right",
  },
},
  emits: ["hide"],
  setup(__props, { emit }) {

const props = __props;

const { logged } = useUser();


const showPasswordRecovery = ref(false);
if (props.needsUser) {
  if (typeof props.needsUser === "function")
    watch(logged, (newVal, oldVal) => {
      if (newVal === true) {
        emit("hide");
        props.needsUser();
      }
    });
}

return { __sfc: true,logged, props, emit, showPasswordRecovery }
}

}