import { onMounted, computed } from "vue";
import { templateRef, useMediaQuery } from "@vueuse/core";
import { useCdn } from "/composables/useCdn";
import useTailwindCss from "/composables/useTailwindCss";


export default {
  __name: 'index',
  props: {
  portraitPic: {
    type: Object,
    required: true,
  },
  landscapePic: {
    type: Object,
    required: true,
  },
  asFixedImage: {
    type: Boolean,
    required: false,
    default: true,
  },
},
  setup(__props) {

const props = __props;



const tpl_root = templateRef("tpl_root");
const tpl_curtain = templateRef("tpl_curtain");
const { fromCloudinary } = useCdn();
const { screens } = useTailwindCss();
const isLGScreen = useMediaQuery(`(min-width: ${screens.lg.min})`);
const isXLScreen = useMediaQuery(`(min-width: ${screens.xl.min})`);
const is2XLScreen = useMediaQuery(`(min-width: ${screens["2xl"].min})`);

const landscapePicSrc = computed(() => {
  if (is2XLScreen.value) {
    return fromCloudinary(
      props.landscapePic.src,
      props.landscapePic.sizes["2xl"]
    );
  } else if (isXLScreen.value) {
    return fromCloudinary(
      props.landscapePic.src,
      props.landscapePic.sizes["xl"]
    );
  } else if (isLGScreen.value) {
    return fromCloudinary(
      props.landscapePic.src,
      props.landscapePic.sizes["lg"]
    );
  } else {
    return fromCloudinary(
      props.landscapePic.src,
      props.landscapePic.sizes["md"]
    );
  }
});

onMounted(() => {
  gsap.fromTo(
    tpl_curtain.value,
    { opacity: 0.3 },
    {
      scrollTrigger: {
        trigger: tpl_root.value,
        start: "top bottom",
        end: "top 10%",
        scrub: true,
        invalidateOnRefresh: true,
      },
      opacity: 0,
      ease: "expo.inOut",
    }
  );
});

return { __sfc: true,props, tpl_root, tpl_curtain, fromCloudinary, screens, isLGScreen, isXLScreen, is2XLScreen, landscapePicSrc }
}

}