import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2688220e"
import script from "./index.vue?vue&type=script&setup=true&lang=js"
export * from "./index.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BtnIconSecondary: require('/var/www/html/glamoraV2/frontend/components/btn/icon/secondary/index.vue').default,VectorGlamora: require('/var/www/html/glamoraV2/frontend/components/vector/glamora/index.vue').default,Btn: require('/var/www/html/glamoraV2/frontend/components/btn/index.vue').default,BtnNavPrimary: require('/var/www/html/glamoraV2/frontend/components/btn/nav/primary/index.vue').default,BtnNavIconSecondary: require('/var/www/html/glamoraV2/frontend/components/btn/nav/icon/secondary/index.vue').default,BtnLang: require('/var/www/html/glamoraV2/frontend/components/btn/lang/index.vue').default,ToolbarHeaderSpecialEditionsCollection: require('/var/www/html/glamoraV2/frontend/components/toolbar/header/special-editions-collection/index.vue').default,ToolbarHeaderFacets: require('/var/www/html/glamoraV2/frontend/components/toolbar/header/facets/index.vue').default,ToolbarHeaderPressLounge: require('/var/www/html/glamoraV2/frontend/components/toolbar/header/press-lounge/index.vue').default,ToolbarHeaderCataloguesBrandTools: require('/var/www/html/glamoraV2/frontend/components/toolbar/header/catalogues-brand-tools/index.vue').default,ToolbarHeaderDiary: require('/var/www/html/glamoraV2/frontend/components/toolbar/header/diary/index.vue').default,ToolbarHeaderAccount: require('/var/www/html/glamoraV2/frontend/components/toolbar/header/account/index.vue').default,ToolbarHeaderSubmenu: require('/var/www/html/glamoraV2/frontend/components/toolbar/header/submenu/index.vue').default,ModalAccount: require('/var/www/html/glamoraV2/frontend/components/modal/account/index.vue').default,ModalMenu: require('/var/www/html/glamoraV2/frontend/components/modal/menu/index.vue').default,ModalSearch: require('/var/www/html/glamoraV2/frontend/components/modal/search/index.vue').default,ModalSampleCta: require('/var/www/html/glamoraV2/frontend/components/modal/sample/cta/index.vue').default})
