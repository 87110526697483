import { useDataLayer } from "/composables/useDataLayer.js";


export default {
  __name: 'index',
  props: {
  visible: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props) {

const props = __props;



const { pushDataLayer } = useDataLayer();

return { __sfc: true,props, pushDataLayer }
}

}