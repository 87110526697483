export default {
  __name: 'index',
  props: {
  tag: {
    type: String,
    required: false,
    validator: function (value) {
      return ["article", "div"].indexOf(value) !== -1;
    },
    default: "article",
  },
  blocks: {
    type: Array,
    required: false,
  },
  gap: {
    type: String,
    required: false,
    validator: function (value) {
      return ["relaxed", "tight"].indexOf(value) !== -1;
    },
    default: "relaxed",
  }
},
  setup(__props) {

const props = __props;



return { __sfc: true,props }
}

}