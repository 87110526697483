export default {
  __name: 'index',
  props: {
  tag: {
    type: String,
    required: false,
    default: "div",
  },
  variant: {
    type: String,
    required: false,
    validator: function (value) {
      return ["base", "rich"].indexOf(value) !== -1;
    },
    default: "base",
  },
  useVerticalRhythm: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props) {

const props = __props;



return { __sfc: true,props }
}

}