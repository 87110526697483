import { useUser } from "/composables/useUser";

export default {
  __name: 'index',
  setup(__props) {

const { user } = useUser();

return { __sfc: true,user }
}

}