export default function (to, from, savedPosition) {
  window.$nuxt.$store.commit("setSavedPosition", savedPosition);
  return new Promise((resolve, reject) => {
    function resolvePromise() {
      window.removeEventListener("resolve-scroll-behavior", resolvePromise);
      resolve(savedPosition || { x: 0, y: 0 });
    }
    window.addEventListener("resolve-scroll-behavior", resolvePromise);
  });
}
