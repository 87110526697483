export const useDataLayer = () => {
  function pushDataLayer(obj) {
    try {
      if (typeof window === "undefined") {
        return;
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(obj);
    } catch (error) {
      console.log("[useDataLayer] Error: ", error);
    }
  }

  return {
    pushDataLayer,
  };
};
