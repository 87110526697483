// formulate.config.js
import { it, es, en, fr, de } from "@braid/vue-formulate-i18n";
// import { parsePhoneNumberFromString } from "libphonenumber-js";

export default {
  mimes: {
    image: "image/*",
    text: ".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  validationNameStrategy: ["validationName", "label"],
  plugins: [it, es, en, fr, de],
  locale: "en",
  rules: {
    async VIES({ value }, countryCode) {
      if (!value || !countryCode) {
        return false;
      }

      // regexp for validating international VAT numbers, can contain only digits and letters and max length is 20
      const vatRegex = /^[a-zA-Z0-9]{1,20}$/;
      if (!vatRegex.test(value)) {
        return false;
      }

      const response = await fetch(
        `/sm/api/utils/validate-vat/${countryCode}/${value}`
      );
      const data = await response.json();
      return data.valid;
    },
    validateTel({ value }) {
      // if (!value) {
      //   return;
      // }
      // return parsePhoneNumberFromString(value.toString());
      // validate phone number with a regexp
      const phoneRegex = /^\+?[0-9]{6,14}$/;
      return phoneRegex.test(value);
    },
  },
};
