import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _157ad7d3 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2e602886 = () => interopDefault(import('../pages/_locale/index.vue' /* webpackChunkName: "pages/_locale/index" */))
const _2f5b21e3 = () => interopDefault(import('../pages/_locale/404.vue' /* webpackChunkName: "pages/_locale/404" */))
const _4cd8773e = () => interopDefault(import('../pages/_locale/advanced-search/index.vue' /* webpackChunkName: "pages/_locale/advanced-search/index" */))
const _2c96589c = () => interopDefault(import('../pages/_locale/bespoke-design-service/index.vue' /* webpackChunkName: "pages/_locale/bespoke-design-service/index" */))
const _3cd21dc3 = () => interopDefault(import('../pages/_locale/brand-tools/index.vue' /* webpackChunkName: "pages/_locale/brand-tools/index" */))
const _0a650cc8 = () => interopDefault(import('../pages/_locale/cart/index.vue' /* webpackChunkName: "pages/_locale/cart/index" */))
const _45b0f092 = () => interopDefault(import('../pages/_locale/catalogues/index.vue' /* webpackChunkName: "pages/_locale/catalogues/index" */))
const _73604e24 = () => interopDefault(import('../pages/_locale/checkout/index.vue' /* webpackChunkName: "pages/_locale/checkout/index" */))
const _e1cb9caa = () => interopDefault(import('../pages/_locale/company/index.vue' /* webpackChunkName: "pages/_locale/company/index" */))
const _d57ed2e4 = () => interopDefault(import('../pages/_locale/confirm-account/index.vue' /* webpackChunkName: "pages/_locale/confirm-account/index" */))
const _4f2ff990 = () => interopDefault(import('../pages/_locale/contacts.vue' /* webpackChunkName: "pages/_locale/contacts" */))
const _43433fbb = () => interopDefault(import('../pages/_locale/contacts/index.vue' /* webpackChunkName: "pages/_locale/contacts/index" */))
const _2483f4e3 = () => interopDefault(import('../pages/_locale/contacts/_id.vue' /* webpackChunkName: "pages/_locale/contacts/_id" */))
const _82517bae = () => interopDefault(import('../pages/_locale/cookie-policy/index.vue' /* webpackChunkName: "pages/_locale/cookie-policy/index" */))
const _dad2152c = () => interopDefault(import('../pages/_locale/creative-collection/index.vue' /* webpackChunkName: "pages/_locale/creative-collection/index" */))
const _4e7cf231 = () => interopDefault(import('../pages/_locale/diary/index.vue' /* webpackChunkName: "pages/_locale/diary/index" */))
const _31c7f684 = () => interopDefault(import('../pages/_locale/faq/index.vue' /* webpackChunkName: "pages/_locale/faq/index" */))
const _e5f56440 = () => interopDefault(import('../pages/_locale/glamora-showroom/index.vue' /* webpackChunkName: "pages/_locale/glamora-showroom/index" */))
const _1e57d94c = () => interopDefault(import('../pages/_locale/info-and-legal/index.vue' /* webpackChunkName: "pages/_locale/info-and-legal/index" */))
const _306f8a99 = () => interopDefault(import('../pages/_locale/materials-and-installation/index.vue' /* webpackChunkName: "pages/_locale/materials-and-installation/index" */))
const _c68cc082 = () => interopDefault(import('../pages/_locale/privacy-policy/index.vue' /* webpackChunkName: "pages/_locale/privacy-policy/index" */))
const _a4106f6c = () => interopDefault(import('../pages/_locale/professional-consulting/index.vue' /* webpackChunkName: "pages/_locale/professional-consulting/index" */))
const _1510835a = () => interopDefault(import('../pages/_locale/professionals/index.vue' /* webpackChunkName: "pages/_locale/professionals/index" */))
const _78907d80 = () => interopDefault(import('../pages/_locale/signup/index.vue' /* webpackChunkName: "pages/_locale/signup/index" */))
const _4015481e = () => interopDefault(import('../pages/_locale/special-editions/index.vue' /* webpackChunkName: "pages/_locale/special-editions/index" */))
const _d2d33ab6 = () => interopDefault(import('../pages/_locale/terms-of-sale/index.vue' /* webpackChunkName: "pages/_locale/terms-of-sale/index" */))
const _15ef348e = () => interopDefault(import('../pages/_locale/account/moodboards/index.vue' /* webpackChunkName: "pages/_locale/account/moodboards/index" */))
const _12ecedaf = () => interopDefault(import('../pages/_locale/account/orders/index.vue' /* webpackChunkName: "pages/_locale/account/orders/index" */))
const _0ad410a7 = () => interopDefault(import('../pages/_locale/account/personal-info/index.vue' /* webpackChunkName: "pages/_locale/account/personal-info/index" */))
const _66bb5423 = () => interopDefault(import('../pages/_locale/account/reset-password/index.vue' /* webpackChunkName: "pages/_locale/account/reset-password/index" */))
const _8265afce = () => interopDefault(import('../pages/_locale/account/shopping-address/index.vue' /* webpackChunkName: "pages/_locale/account/shopping-address/index" */))
const _2cfb385b = () => interopDefault(import('../pages/_locale/checkout/thank-you/index.vue' /* webpackChunkName: "pages/_locale/checkout/thank-you/index" */))
const _17801fc3 = () => interopDefault(import('../pages/_locale/diary/all/index.vue' /* webpackChunkName: "pages/_locale/diary/all/index" */))
const _2413494f = () => interopDefault(import('../pages/_locale/diary/insights/index.vue' /* webpackChunkName: "pages/_locale/diary/insights/index" */))
const _423efe3d = () => interopDefault(import('../pages/_locale/diary/inspirations/index.vue' /* webpackChunkName: "pages/_locale/diary/inspirations/index" */))
const _2e012f2e = () => interopDefault(import('../pages/_locale/diary/projects/index.vue' /* webpackChunkName: "pages/_locale/diary/projects/index" */))
const _c49918b8 = () => interopDefault(import('../pages/_locale/press-lounge/brand/index.vue' /* webpackChunkName: "pages/_locale/press-lounge/brand/index" */))
const _f7ec8a9c = () => interopDefault(import('../pages/_locale/press-lounge/collections/index.vue' /* webpackChunkName: "pages/_locale/press-lounge/collections/index" */))
const _627924ba = () => interopDefault(import('../pages/_locale/diary/insights/_id/index.vue' /* webpackChunkName: "pages/_locale/diary/insights/_id/index" */))
const _68c4b2a8 = () => interopDefault(import('../pages/_locale/diary/inspirations/_id/index.vue' /* webpackChunkName: "pages/_locale/diary/inspirations/_id/index" */))
const _55330319 = () => interopDefault(import('../pages/_locale/diary/projects/_id/index.vue' /* webpackChunkName: "pages/_locale/diary/projects/_id/index" */))
const _592d3326 = () => interopDefault(import('../pages/_locale/landing/_id.vue' /* webpackChunkName: "pages/_locale/landing/_id" */))
const _4a84f1e5 = () => interopDefault(import('../pages/_locale/moodboard/_id.vue' /* webpackChunkName: "pages/_locale/moodboard/_id" */))
const _2c1ea145 = () => interopDefault(import('../pages/_locale/product/_id.vue' /* webpackChunkName: "pages/_locale/product/_id" */))
const _aa6efb90 = () => interopDefault(import('../pages/_locale/special-editions/_collection/concept/index.vue' /* webpackChunkName: "pages/_locale/special-editions/_collection/concept/index" */))
const _36fcc12c = () => interopDefault(import('../pages/_locale/special-editions/_collection/products/index.vue' /* webpackChunkName: "pages/_locale/special-editions/_collection/products/index" */))
const _24eb6df8 = () => interopDefault(import('../pages/_locale/materials-and-installation/_material/_id.vue' /* webpackChunkName: "pages/_locale/materials-and-installation/_material/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _157ad7d3,
    name: "index"
  }, {
    path: "/:locale",
    component: _2e602886,
    name: "locale"
  }, {
    path: "/:locale/404",
    component: _2f5b21e3,
    name: "locale-404"
  }, {
    path: "/:locale/advanced-search",
    component: _4cd8773e,
    name: "locale-advanced-search"
  }, {
    path: "/:locale/bespoke-design-service",
    component: _2c96589c,
    name: "locale-bespoke-design-service"
  }, {
    path: "/:locale/brand-tools",
    component: _3cd21dc3,
    name: "locale-brand-tools"
  }, {
    path: "/:locale/cart",
    component: _0a650cc8,
    name: "locale-cart"
  }, {
    path: "/:locale/catalogues",
    component: _45b0f092,
    name: "locale-catalogues"
  }, {
    path: "/:locale/checkout",
    component: _73604e24,
    name: "locale-checkout"
  }, {
    path: "/:locale/company",
    component: _e1cb9caa,
    name: "locale-company"
  }, {
    path: "/:locale/confirm-account",
    component: _d57ed2e4,
    name: "locale-confirm-account"
  }, {
    path: "/:locale/contacts",
    component: _4f2ff990,
    children: [{
      path: "",
      component: _43433fbb,
      name: "locale-contacts"
    }, {
      path: ":id",
      component: _2483f4e3,
      name: "locale-contacts-id"
    }]
  }, {
    path: "/:locale/cookie-policy",
    component: _82517bae,
    name: "locale-cookie-policy"
  }, {
    path: "/:locale/creative-collection",
    component: _dad2152c,
    name: "locale-creative-collection"
  }, {
    path: "/:locale/diary",
    component: _4e7cf231,
    name: "locale-diary"
  }, {
    path: "/:locale/faq",
    component: _31c7f684,
    name: "locale-faq"
  }, {
    path: "/:locale/glamora-showroom",
    component: _e5f56440,
    name: "locale-glamora-showroom"
  }, {
    path: "/:locale/info-and-legal",
    component: _1e57d94c,
    name: "locale-info-and-legal"
  }, {
    path: "/:locale/materials-and-installation",
    component: _306f8a99,
    name: "locale-materials-and-installation"
  }, {
    path: "/:locale/privacy-policy",
    component: _c68cc082,
    name: "locale-privacy-policy"
  }, {
    path: "/:locale/professional-consulting",
    component: _a4106f6c,
    name: "locale-professional-consulting"
  }, {
    path: "/:locale/professionals",
    component: _1510835a,
    name: "locale-professionals"
  }, {
    path: "/:locale/signup",
    component: _78907d80,
    name: "locale-signup"
  }, {
    path: "/:locale/special-editions",
    component: _4015481e,
    name: "locale-special-editions"
  }, {
    path: "/:locale/terms-of-sale",
    component: _d2d33ab6,
    name: "locale-terms-of-sale"
  }, {
    path: "/:locale/account/moodboards",
    component: _15ef348e,
    name: "locale-account-moodboards"
  }, {
    path: "/:locale/account/orders",
    component: _12ecedaf,
    name: "locale-account-orders"
  }, {
    path: "/:locale/account/personal-info",
    component: _0ad410a7,
    name: "locale-account-personal-info"
  }, {
    path: "/:locale/account/reset-password",
    component: _66bb5423,
    name: "locale-account-reset-password"
  }, {
    path: "/:locale/account/shopping-address",
    component: _8265afce,
    name: "locale-account-shopping-address"
  }, {
    path: "/:locale/checkout/thank-you",
    component: _2cfb385b,
    name: "locale-checkout-thank-you"
  }, {
    path: "/:locale/diary/all",
    component: _17801fc3,
    name: "locale-diary-all"
  }, {
    path: "/:locale/diary/insights",
    component: _2413494f,
    name: "locale-diary-insights"
  }, {
    path: "/:locale/diary/inspirations",
    component: _423efe3d,
    name: "locale-diary-inspirations"
  }, {
    path: "/:locale/diary/projects",
    component: _2e012f2e,
    name: "locale-diary-projects"
  }, {
    path: "/:locale/press-lounge/brand",
    component: _c49918b8,
    name: "locale-press-lounge-brand"
  }, {
    path: "/:locale/press-lounge/collections",
    component: _f7ec8a9c,
    name: "locale-press-lounge-collections"
  }, {
    path: "/:locale/diary/insights/:id",
    component: _627924ba,
    name: "locale-diary-insights-id"
  }, {
    path: "/:locale/diary/inspirations/:id",
    component: _68c4b2a8,
    name: "locale-diary-inspirations-id"
  }, {
    path: "/:locale/diary/projects/:id",
    component: _55330319,
    name: "locale-diary-projects-id"
  }, {
    path: "/:locale/landing/:id?",
    component: _592d3326,
    name: "locale-landing-id"
  }, {
    path: "/:locale/moodboard/:id?",
    component: _4a84f1e5,
    name: "locale-moodboard-id"
  }, {
    path: "/:locale/product/:id?",
    component: _2c1ea145,
    name: "locale-product-id"
  }, {
    path: "/:locale/special-editions/:collection?/concept",
    component: _aa6efb90,
    name: "locale-special-editions-collection-concept"
  }, {
    path: "/:locale/special-editions/:collection?/products",
    component: _36fcc12c,
    name: "locale-special-editions-collection-products"
  }, {
    path: "/:locale/materials-and-installation/:material?/:id?",
    component: _24eb6df8,
    name: "locale-materials-and-installation-material-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
