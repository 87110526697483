export const state = () => ({
  lang: "",
  productCardUseAmbientAsInitial: true,
  fromName: "",
  toName: "",
  fromParams: {},
  fromFullPath: "",
  toFullPath: "",
  wsPath: "",
  wpPath: "",
  host: "",
  protocol: "",
  isIOS: null,
  savedPosition: null,
  paypalClientToken: null,
  contacts: {
    accounting: null,
    careers: null,
    customer_care_service: null,
    headquarters: null,
    press: null,
    professional_consulting: null,
    sales_representatives: null,
    showrooms: null,
    become_a_partner: null,
    form_hubspot: null,
  },
});

export const mutations = {
  setSavedPosition(state, value) {
    state.savedPosition = value;
  },
  setIsIOS(state, value) {
    state.isIOS = value;
  },
  setIsIPhone(state, value) {
    state.isIPhone = value;
  },
  setBasePath(state, path) {
    state.wsPath = `${path}/wp-json/ta/`;
    state.wpPath = `${path}`;
  },
  setproductCardUseAmbient(state, value) {
    this.$cookies.set("glmr_product-card-use-ambient", value.toString(), {
      path: "/",
      maxAge: 60 * 60 * 24 * 365, // 1 anno
    });
    state.productCardUseAmbientAsInitial = value.toString() === "true";
  },
  setFromRoute(state, obj) {
    state.fromParams = obj.fromParams;
    state.fromName = obj.fromName;
    state.toName = obj.toName;
    state.fromFullPath = obj.fromFullPath;
    state.toFullPath = obj.toFullPath;
  },
  setAvifSupport(state, value) {
    state.supportsAvif = value;
  },
  setContacts(state, value) {
    state.contacts.accounting = value.accounting;
    state.contacts.careers = value.careers;
    state.contacts.customer_care_service = value.customer_care_service;
    state.contacts.headquarters = value.headquarters;
    state.contacts.press = value.press;
    state.contacts.professional_consulting = value.professional_consulting;
    state.contacts.sales_representatives = value.sales_representatives;
    state.contacts.showrooms = value.showrooms;
    state.contacts.become_a_partner = value.become_a_partner;
    state.contacts.form_hubspot = value.form_hubspot;
  },
  setLang(state, value) {
    state.lang = value;
  },
  setHost(state, value) {
    state.host = value;
  },
  setProtocol(state, value) {
    state.protocol = value;
  },
  setPaypalClientToken(state, value) {
    state.paypalClientToken = value;
  },
};

export const actions = {
  async nuxtServerInit({ dispatch, commit }, { params, $cookies, req, route }) {
    function isValidLocale(locale) {
      return /^it$|^en$|^de$|^fr$|^es$/.test(locale);
    }

    if (!isValidLocale(params.locale)) {
      return;
    }

    commit("setAvifSupport", /image\/avif/.test(req.headers["accept"]));

    if ($cookies.get("glmr_product-card-use-ambient") !== undefined) {
      commit(
        "setproductCardUseAmbient",
        $cookies.get("glmr_product-card-use-ambient")
      );
    } else {
      commit("setproductCardUseAmbient", true);
    }

    commit("setBasePath", process.env.wsURL);
    commit("setHost", req.headers.host);
    commit("setProtocol", /glamora/.test(req.headers.host) ? "https" : "http");

    if (!params.locale) {
      return;
    }

    // get current route
    await dispatch("labels/load", {
      lang: params.locale,
    });

    await dispatch("geolist/load", {
      lang: params.locale,
    });

    return;
  },
};

export const getters = {
  /*
  "getters": computed properties for stores.
  */
  pageChangeType: (state) => {
    if (
      state.fromName == "locale-contacts" &&
      state.toName == "locale-contacts-id"
    ) {
      return "redirect";
    }

    if (state.fromName == state.toName) {
      return "subroute";
    }

    return false;
  },
};
