import { ref, watch, onMounted } from "vue";
import { useRoute, useRouter, useContext } from "@nuxtjs/composition-api";
import {
  usePageLeave,
  useWindowScroll,
  useWindowSize,
  onClickOutside,
  throttledWatch,
  useMediaQuery,
} from "@vueuse/core";
import { useHeaderToolbar } from "@/composables/useHeaderToolbar";
import useTailwindCss from "/composables/useTailwindCss";
import { useCommerce } from "/composables/useCommerce.js";
import { useUser } from "/composables/useUser";
import { useDataLayer } from "/composables/useDataLayer.js";


export default {
  __name: 'index',
  props: {
  accountButtonEnabled: {
    type: Boolean,
    default: true,
    required: false,
  },
},
  setup(__props) {

const props = __props;



const route = useRoute();
const router = useRouter();
const { store } = useContext();
const { screens } = useTailwindCss();
const { cart } = useCommerce();
const { pushDataLayer } = useDataLayer();
const isLGScreen = useMediaQuery(`(min-width: ${screens.lg.min})`);
const showMenuModal = ref(false);
const showSearchModal = ref(false);
const showAccountModal = ref(false);
const showSampleCtaModal = ref(false);

const submenus = ref({
  lines: [
    {
      title: store.state.labels.creative_collection,
      text: store.state.labels.creative_collection_subline,
      to: "/creative-collection",
      onclickCallback: () => {
        pushDataLayer({
          event: "click_menu_items",
          menu_item_name: "creative-collection",
        });
      },
    },
    {
      title: store.state.labels.special_editions,
      text: store.state.labels.special_editions_subline,
      to: "/special-editions",
      onclickCallback: () => {
        pushDataLayer({
          event: "click_menu_items",
          menu_item_name: "special-editions",
        });
      },
    },
  ],
  about: [
    {
      title: store.state.labels.company,
      // text: store.state.labels.company_subline,
      to: "/company",
      onclickCallback: () => {
        pushDataLayer({
          event: "click_menu_items",
          menu_item_name: "company",
        });
      },
    },
    {
      title: store.state.labels.showroom,
      // text: store.state.labels.showroom_subline,
      to: "/glamora-showroom",
      onclickCallback: () => {
        pushDataLayer({
          event: "click_menu_items",
          menu_item_name: "showroom",
        });
      },
    },
  ],
});
const currentSubmenu = ref([]);
const tpl_root = ref("tpl_root");
const showSubmenu = ref(false);
const isLeft = usePageLeave();

const hide = ref(false);
const { y } = useWindowScroll();
const { height } = useWindowSize();

const { toolbar } = useHeaderToolbar();
const toolbarProp = ref(toolbar);
const { user, logged } = useUser();

function handleHtmlClass() {
  const HAS_TOOLBAR = "app-header-has-toolbar";
  const classList = document.getElementsByTagName("html")[0].classList;
  toolbar.value ? classList.add(HAS_TOOLBAR) : classList.remove(HAS_TOOLBAR);
}

function handleShowSubmenu(submenu) {
  currentSubmenu.value = submenus.value[submenu];
  showSubmenu.value = true;
}

function handleCart(event) {
  if (
    !cart?.value?.item_count &&
    (!logged.value || (logged.value && !user.value.has_orders))
  ) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    showSampleCtaModal.value = true;
  } else router.push(`/${route.value.params.locale}/cart`);
}

watch(toolbarProp, (newVal, oldVal) => {
  handleHtmlClass();
});

watch(showAccountModal, (newVal, oldVal) => {
  if (newVal) showMenuModal.value = false;
});

watch(showSearchModal, (newVal, oldVal) => {
  if (newVal) showMenuModal.value = false;
});

watch(isLGScreen, (newVal, oldVal) => {
  showMenuModal.value = false;
});

watch(route, () => (showSubmenu.value = false));

watch(isLeft, function (newVal, oldVal) {
  if (newVal) showSubmenu.value = false;
});

throttledWatch(
  y,
  (newVal, oldVal) => {
    const classList = document.getElementsByTagName("html")[0].classList;
    const IS_MINIMIZED = "app-header-leave";

    if (oldVal > newVal) {
      hide.value = false;
    } else {
      hide.value = newVal > height.value * 0.45;
    }

    hide.value ? classList.add(IS_MINIMIZED) : classList.remove(IS_MINIMIZED);
  },
  { throttle: 333 }
);

onClickOutside(tpl_root, (event) => (showSubmenu.value = false));

onMounted(() => {
  handleHtmlClass();
});

return { __sfc: true,props, route, router, store, screens, cart, pushDataLayer, isLGScreen, showMenuModal, showSearchModal, showAccountModal, showSampleCtaModal, submenus, currentSubmenu, tpl_root, showSubmenu, isLeft, hide, y, height, toolbar, toolbarProp, user, logged, handleHtmlClass, handleShowSubmenu, handleCart }
}

}