import { ref, watch, computed } from "vue";
import { useContext, useStore } from "@nuxtjs/composition-api";

const state = ref({});
const routeName = ref("");

export const useHeaderToolbar = () => {
  const { store, route } = useContext();
  const { getters } = useStore();

  const toolbar = computed(() => getters["headerToolbar/loadedToolbar"]);

  function setToolbar(type) {
    store.commit("headerToolbar/setToolbar", type);
    routeName.value = route.value.name;
  }

  function getTargetToolbarFromCurrentPath() {
    let toolbar;

    switch (route.value.name) {
      case "locale-account-moodboards":
      case "locale-account-orders":
      case "locale-account-shopping-address":
      case "locale-account-personal-info":
        toolbar = "account";
        break;

      case "locale-professionals":
        toolbar = "professionals";
        break;

      case "locale-advanced-search":
        toolbar = "advanced-search";
        break;

      case "locale-special-editions-collection-concept":
      case "locale-special-editions-collection-products":
        toolbar = "special-editions-collection";
        break;

      case "locale-creative-collection":
        toolbar = "creative-collection";
        break;

      case "locale-press-lounge-collections":
      case "locale-press-lounge-brand":
        toolbar = "press-lounge";
        break;

      case "locale-catalogues":
      case "locale-brand-tools":
        toolbar = "catalogues-brand-tools";
        break;

      case "locale-diary-all":
      case "locale-diary-projects":
      case "locale-diary-inspirations":
      case "locale-diary-insights":
        toolbar = "diary";
        break;
    }

    return toolbar;
  }

  function getToolbar() {
    return getters["headerToolbar/loadedToolbar"];
  }

  function setDataByRouteName(data) {
    store.commit("headerToolbar/setDataByRouteName", {
      routeName: route.value.name,
      data: data,
    });
  }

  const toolbarData = ref(
    store.state.headerToolbar.data[routeName.value] || {}
  );

  watch(
    () => store.state.headerToolbar.data.updateTime,
    (value) => {
      toolbarData.value = store.state.headerToolbar.data[routeName.value];
    }
  );

  return {
    setDataByRouteName,
    toolbarData /* : computed(
      () => store.state.headerToolbar.data[routeName.value]
    ) */,
    toolbar,
    setToolbar,
    getToolbar,
    getTargetToolbarFromCurrentPath,
  };
};
