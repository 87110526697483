import { ref, computed, watch } from "vue";
import { useContext } from "@nuxtjs/composition-api";

export default {
  __name: 'index',
  setup(__props) {

const { store } = useContext();

const boo = ref(
  store.state.productCardUseAmbientAsInitial.toString() === "true"
);

watch(boo, (newVal, oldVal) => {
  store.commit("setproductCardUseAmbient", newVal.toString() === "true");
});

return { __sfc: true,store, boo }
}

}