import { ref, onMounted, nextTick } from "vue";
import { useMutationObserver } from "@vueuse/core";

export default {
  __name: 'index',
  props: {
  alwaysOpen: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props) {

const props = __props;


const tpl_root = ref(null);
const detailsList = ref(null);

if (!props.alwaysOpen) {
  useMutationObserver(
    tpl_root,
    (mutations) => {
      if (!detailsList.value) return;

      if (mutations[0].oldValue !== "") {
        for (let i = 0, len = detailsList.value.length; i < len; i++) {
          if (detailsList.value[i] !== mutations[0].target) {
            detailsList.value[i].removeAttribute("open");
          }
        }
      }
    },
    {
      subtree: true,
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ["open"],
    }
  );
}

onMounted(() => {
  if (!props.alwaysOpen) {
    nextTick(() => {
      nextTick(() => {
        detailsList.value = tpl_root.value.getElementsByTagName("details");
      });
    });
  }
});

return { __sfc: true,props, tpl_root, detailsList }
}

}