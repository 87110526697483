export default {
  __name: 'index',
  props: {
  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props) {

const props = __props;



return { __sfc: true,props }
}

}