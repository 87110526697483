import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4b5015f2"
import script from "./index.vue?vue&type=script&setup=true&lang=js"
export * from "./index.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextHtml: require('/var/www/html/glamoraV2/frontend/components/text/html/index.vue').default,GridProducts: require('/var/www/html/glamoraV2/frontend/components/grid/products/index.vue').default,GridSpecialEditions: require('/var/www/html/glamoraV2/frontend/components/grid/special-editions/index.vue').default,GridDiary: require('/var/www/html/glamoraV2/frontend/components/grid/diary/index.vue').default,GridServices: require('/var/www/html/glamoraV2/frontend/components/grid/services/index.vue').default,GridPress: require('/var/www/html/glamoraV2/frontend/components/grid/press/index.vue').default,GridMosaic: require('/var/www/html/glamoraV2/frontend/components/grid/mosaic/index.vue').default,GridAwards: require('/var/www/html/glamoraV2/frontend/components/grid/awards/index.vue').default,GridLauncher: require('/var/www/html/glamoraV2/frontend/components/grid/launcher/index.vue').default,GridCatalogues: require('/var/www/html/glamoraV2/frontend/components/grid/catalogues/index.vue').default,BtnLoadMore: require('/var/www/html/glamoraV2/frontend/components/btn/load-more/index.vue').default,Block: require('/var/www/html/glamoraV2/frontend/components/block/index.vue').default})
