import { computed } from "vue";


export default {
  __name: 'index',
  props: {
  title: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  stretched: {
    type: Boolean,
    required: false,
    default: false,
  },
  pressed: {
    type: Boolean,
    required: false,
    default: false,
  },
  size: {
    type: String,
    required: false,
    validator: function (value) {
      return ["big", "regular", "small"].indexOf(value) !== -1;
    },
    default: "regular",
  },
  to: {
    type: [String, Object],
    required: false,
  },
  download: {
    type: [String, Object],
    required: false,
  },
  externalLink: {
    type: String,
    required: false,
  },
  phone: {
    type: String,
    required: false,
    validator: function (value) {
      return parsePhoneNumberFromString(value) ? true : false;
    },
  },
  email: {
    type: String,
    required: false,
    validator: function (value) {
      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        value
      );
    },
  },
  type: {
    type: String,
    required: false,
    validator: function (value) {
      return ["button", "submit"].indexOf(value) !== -1;
    },
    default: "button",
  },
  buttonTag: {
    type: String,
    required: false,
    validator: function (value) {
      return ["summary", "button"].indexOf(value) !== -1;
    },
    default: "button",
  },
},
  setup(__props) {

const props = __props;



const iconSize = computed(() => {
  let s;
  switch (props.size) {
    case "small":
      s = "4";
      break;

    case "big":
      s = "8";
      break;

    case "regular":
    default:
      s = "6";
      break;
  }
  return s;
});

return { __sfc: true,props, iconSize }
}

}