import { templateRef, onClickOutside } from "@vueuse/core";
import { useScrollDisable } from "/composables/useScrollDisable";
import {
  watch,
  onMounted,
  onBeforeUnmount
} from "vue";
import {
  useRoute,
} from "@nuxtjs/composition-api";
import SpecialEditions from "~/pages/_locale/special-editions/index.vue";


export default {
  __name: 'index',
  props: {
  visible: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideOnRouteChange: {
    type: Boolean,
    required: false,
    default: true,
  },
  useClose: {
    type: Boolean,
    required: false,
    default: true,
  },
  closeClass: {
    type: String,
    required: false,
  },
  labelledby: {
    type: String,
    required: true,
  },
  describedby: {
    type: String,
    required: false,
  },
  backdrop: {
    type: [Boolean, String],
    required: false,
    default: true,
  },
  transition: {
    type: String,
    required: false,
    validator: function (value) {
      return (
        [
          "fade",
          "slide-from-right",
          "slide-from-left",
          "slide-from-bottom",
          "scale-from-up",
          "scale-from-down",
        ].indexOf(value) !== -1
      );
    },
    default: "fade",
  },
},
  emits: ["hide"],
  setup(__props, { emit }) {

const props = __props;


const route = useRoute();

const { disableScroll, enableScroll } = useScrollDisable();
const tpl_bg = templateRef("tpl_bg");
const tpl_close = templateRef("tpl_close");
const tpl_contentInner = templateRef("tpl_contentInner");

let transitionTL;

function beforeEnter(el) {
  gsap.set(el, { autoAlpha: 0 });
}

function enter(el, done) {
  if (transitionTL) {
    transitionTL.kill();
    transitionTL = null;
  }

  transitionTL = new gsap.timeline({
    onComplete: () => {
      transitionTL = null;
      done();
    },
  });

  transitionTL.set(el, { autoAlpha: 1 });

  if (props.backdrop === true || props.backdrop === "static") {
    transitionTL.fromTo(
      tpl_bg.value,
      { autoAlpha: 0 },
      { duration: 1.7, autoAlpha: 0.7, ease: "expo.out" }
    );
  }

  if (props.useClose) {
    transitionTL.fromTo(
      tpl_close.value,
      { x: 200 },
      { duration: 0.5, x: 0, ease: "power2.out" },
      0.2
    );
  }

  switch (props.transition) {
    case "fade":
      transitionTL.fromTo(
        tpl_contentInner.value,
        { opacity: 0 },
        { duration: 0.5, opacity: 1, ease: "power2.out" },
        0.2
      );
      break;

    case "slide-from-right":
    case "slide-from-left":
      transitionTL.fromTo(
        tpl_contentInner.value,
        { xPercent: props.transition === "slide-from-right" ? 110 : -110 },
        { duration: 0.65, xPercent: 0, ease: "expo.out" },
        0.2
      );
      break;

    case "slide-from-bottom":
      transitionTL.fromTo(
        tpl_contentInner.value,
        { yPercent: 110 },
        { duration: 0.5, yPercent: 0, ease: "power2.out" },
        0.2
      );
      break;

    case "scale-from-up":
      transitionTL.fromTo(
        tpl_contentInner.value,
        { scale: 1.05, opacity: 0 },
        { duration: 0.5, scale: 1, opacity: 1, ease: "power2.out" },
        0.2
      );
      break;

    case "scale-from-down":
      transitionTL.fromTo(
        tpl_contentInner.value,
        { scale: 0.95, opacity: 0 },
        { duration: 0.5, scale: 1, opacity: 1, ease: "power2.out" },
        0.2
      );
      break;
  }
}

function leave(el, done) {
  if (transitionTL) {
    transitionTL.kill();
    transitionTL = null;
  }

  transitionTL = new gsap.timeline({
    onComplete: () => {
      transitionTL = null;
      done();
    },
  });

  switch (props.transition) {
    case "fade":
      transitionTL.to(tpl_contentInner.value, {
        duration: 0.5,
        opacity: 0,
        ease: "power2.out",
      });
      break;

    case "slide-from-right":
    case "slide-from-left":
      transitionTL.to(tpl_contentInner.value, {
        duration: 0.7,
        xPercent: props.transition === "slide-from-right" ? 110 : -110,
        ease: "expo.inOut",
      });
      break;

    case "slide-from-bottom":
      transitionTL.to(tpl_contentInner.value, {
        duration: 0.5,
        yPercent: 110,
        ease: "power2.out",
      });
      break;

    case "scale-from-up":
      transitionTL.to(tpl_contentInner.value, {
        duration: 0.5,
        opacity: 0,
        scale: 1.05,
        ease: "power2.out",
      });
      break;

    case "scale-from-down":
      transitionTL.to(tpl_contentInner.value, {
        duration: 0.5,
        opacity: 0,
        scale: 0.95,
        ease: "power2.out",
      });
      break;
  }

  if (props.useClose) {
    transitionTL.to(
      tpl_close.value,
      { duration: 0.5, x: 200, ease: "power2.inOut", force3D: "auto" },
      0
    );
  }

  if (props.backdrop === true || props.backdrop === "static") {
    transitionTL.to(
      tpl_bg.value,
      { duration: 0.5, autoAlpha: 0, ease: "expo.inOut", force3D: "auto" },
      props.transition === "fade" ? 0 : 0.2
    );
  }

  transitionTL.set(el, { autoAlpha: 0 });
}

onClickOutside(tpl_contentInner, (event) => {
  if (props.backdrop === true) emit("hide");
});

watch(route, () => {
  if (props.hideOnRouteChange) emit("hide");
});

onMounted(() => {
  watch(
    () => props.visible,
    (newValue) => {
      newValue ? disableScroll() : enableScroll();
    }
  );
});

onBeforeUnmount(() => {
  enableScroll();
});

return { __sfc: true,props, route, emit, disableScroll, enableScroll, tpl_bg, tpl_close, tpl_contentInner, transitionTL, beforeEnter, enter, leave }
}

}