import CoCartAPI from "@cocart/cocart-rest-api";

export default ({ app }, inject) => {
  // V1
  const CoCartV1 = new CoCartAPI({
    url: process.env.wsURL,
    version: "cocart/v1",
  });
  inject("CoCartV1", CoCartV1);
};
