export default {
  __name: 'index',
  props: {
  pic: {
    type: Object,
    required: false,
  },
  text: {
    type: String,
    required: false,
  },
  picWrapperClass: {
    type: String,
    required: false,
    default: "w-full",
  },
  picClass: {
    type: String,
    required: false,
    default: "w-full",
  },
  useEnterAnims: {
    type: Boolean,
    required: false,
    default: true,
  },
},
  setup(__props) {

const props = __props;



return { __sfc: true,props }
}

}