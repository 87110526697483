import { ref, computed } from "vue";

export default {
  __name: 'index',
  props: {
  variant: {
    validator: function (value) {
      return ["normal", "relaxed", "recirculation"].indexOf(value) !== -1;
    },
    default: "normal",
  },
  cardVariant: {
    validator: function (value) {
      return (
        ["ambient", "user-preference", "first-variant"].indexOf(value) !== -1
      );
    },
    default: "ambient",
  },
  title: {
    type: String,
    required: false,
  },
  useMoodboard: {
    type: Boolean,
    required: false,
    default: true,
  },
  useMaterials: {
    type: Boolean,
    required: false,
    default: false,
  },
  items: {
    type: Array,
    required: true,
  },
},
  setup(__props) {

const props = __props;



const initialSlide = computed(() => {
  return props.cardVariant && props.variant !== "recirculation"
    ? props.cardVariant
    : "ambient";
});

const moodboardEnabled = computed(() =>
  props.variant === "recirculation" ? false : props.useMoodboard
);

const moodboardSlug = ref("");

function onAddToMoodboard(slug) {
  moodboardSlug.value = slug;
}

return { __sfc: true,props, initialSlide, moodboardEnabled, moodboardSlug, onAddToMoodboard }
}

}