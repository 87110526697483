import { onMounted } from "vue";
import { useRoute } from "@nuxtjs/composition-api";
import { useDataLayer } from "~/composables/useDataLayer";


export default {
  __name: 'blank',
  setup(__props) {

const { pushDataLayer } = useDataLayer();
const route = useRoute();
onMounted(() => {
  pushDataLayer({
    event: "pageview",
    page_path: route.value.fullPath,
    page_title: document.title,
  });
});

function pageAfterEnter(event) {
  pushDataLayer({
    event: "pageview",
    page_path: route.value.fullPath,
    page_title: document.title,
  });
}

return { __sfc: true,pushDataLayer, route, pageAfterEnter }
}

}