import { computed } from "vue";
import { useContext, useRouter } from "@nuxtjs/composition-api";

export const useUser = () => {
  const { store, route } = useContext();
  const router = useRouter();

  const user = computed(() => store.state.user);

  async function login(payload) {
    return await store.dispatch("user/userLogin", payload);
  }

  async function logout() {
    const response = await store.dispatch("user/userLogout", {
      id: user.value.id,
    });
    if (
      response.status == "success" &&
      /^locale-account-moodboards$|^locale-account-personal-info$|^locale-account-orders$|^locale-checkout$/.test(
        route.value.name
      )
    ) {
      router.replace(`/${route.value.params.locale}`);
    }

    return response;
  }

  async function signup(payload) {
    return await store.dispatch("user/userSignup", payload);
  }

  async function update(payload) {
    return await store.dispatch("user/userUpdate", payload);
  }

  async function remove(payload) {
    return await store.dispatch("user/userRemove", payload);
  }

  async function passwordUpdate(payload) {
    return await store.dispatch("user/passwordUpdate", payload);
  }

  async function passwordRecovery(payload) {
    return await store.dispatch("user/passwordRecovery", payload);
  }

  async function passwordReset(payload) {
    return await store.dispatch("user/passwordReset", payload);
  }

  async function setShoppingAddress(payload) {
    return await store.dispatch("user/setShoppingAddress", payload);
  }

  return {
    logged: computed(() => user.value.token !== null),
    shoppingAddress: computed(() => user.value.shopping_address),
    user,
    login,
    logout,
    signup,
    update,
    remove,
    passwordUpdate,
    passwordRecovery,
    passwordReset,
    setShoppingAddress,
  };
};
