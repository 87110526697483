import { useRoute }  from "@nuxtjs/composition-api";
import { useUser } from "/composables/useUser";

export default {
  __name: 'index',
  props: {
  visible: {
    type: Boolean,
    required: false,
    default: false,
  }
},
  setup(__props) {

const props = __props;


const route = useRoute();
const { logged } = useUser();

return { __sfc: true,props, route, logged }
}

}