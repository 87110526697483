export default {
  __name: 'index',
  props: {
  name: {
    type: String,
    required: true,
  },
  options: {
    type: [Object, Array],
    required: true,
  },
  wrapperClass: {
    type: String,
    required: false,
    default: "",
  },
  elementClass: {
    type: String,
    required: false,
    default: "",
  },
  inputClass: {
    type: String,
    required: false,
    default: "",
  },
},
  setup(__props) {

const props = __props;



return { __sfc: true,props }
}

}