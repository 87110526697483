import { useCdn } from "/composables/useCdn";
import { useDataLayer } from "/composables/useDataLayer.js";


export default {
  __name: 'index',
  props: {
  sku: {
    type: String,
    required: true,
  },
  productSku: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: true,
  },
  moduleA: {
    type: String,
    required: true,
  },
  moduleB: {
    type: String,
    required: false,
  },
  useControls: {
    type: Boolean,
    required: false,
    default: true,
  },
  pic: {
    type: Object,
    required: false,
  },
  picWatermark: {
    type: Object,
    required: false,
  },
  download: {
    type: Object,
    required: false,
  },
  listId: {
    type: String,
    required: false,
  },
},
  setup(__props) {

const props = __props;

const { pushDataLayer } = useDataLayer();
const { fromCloudinary } = useCdn();


return { __sfc: true,pushDataLayer, fromCloudinary, props }
}

}