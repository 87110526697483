export default {
  __name: 'index',
  props: {
  items: {
    type: Array,
    required: true,
  },
  title: {
    type: String,
    required: false,
  },
},
  setup(__props) {

const props = __props;



return { __sfc: true,props }
}

}