import { useFiltersToolbar } from "/composables/useFiltersToolbar";
import isEmpty from "lodash/isEmpty";


export default {
  __name: 'index',
  props: {
  visible: {
    type: Boolean,
    required: false,
    default: false,
  },
  // facets: {
  //   type: Object,
  //   required: true,
  // }
},
  setup(__props) {

const props = __props;

const {
  vModel,
  filtersQuery,
  updateFilters,
  updateFiltersModel,
  loadFilteredItems,
  toolbarFacets: facets,
  filtersInteractive,
} = useFiltersToolbar();



function sortFacet(facet) {
  facet = Object.entries(facet).map(([key, value]) => {
    return { label: value, value: key };
  });
  return facet.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
}

return { __sfc: true,vModel, filtersQuery, updateFilters, updateFiltersModel, loadFilteredItems, facets, filtersInteractive, props, sortFacet, isEmpty }
}

}