export default {
  __name: 'index',
  props: {
  tag: {
    type: String,
    required: false,
    validator: function (value) {
      return ["section", "div", "figure"].indexOf(value) !== -1;
    },
    default: "section",
  },
  contentVisibility: {
    type: String,
    required: false,
    validator: function (value) {
      return ["auto", "hidden", "visible"].indexOf(value) !== -1;
    },
    default: "visible",
  },
  contentContain: {
    type: Boolean,
    required: false,
    default: true,
  },
},
  setup(__props) {

const props = __props;



return { __sfc: true,props }
}

}