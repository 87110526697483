const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['paypal'] = require('../middleware/paypal.js')
middleware['paypal'] = middleware['paypal'].default || middleware['paypal']

middleware['router'] = require('../middleware/router.js')
middleware['router'] = middleware['router'].default || middleware['router']

export default middleware
