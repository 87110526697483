import axios from "axios";
import Vue from "vue";
import pick from "lodash/pick";
import omit from "lodash/omit";

const model = {
  city: null,
  confirmed: null,
  country: null,
  email: null,
  firstname: null,
  id: null,
  jobrole: null,
  lastname: null,
  privacy_policy: null,
  province: null,
  region: null,
  registration_lang: null,
  state: null,
  telephone: null,
  zip: null,
  token: null,
  telephone_intl_prefix_isocode: null,
  telephone_intl_prefix_number: null,
  telephone_intl_prefix: null,
  has_orders: null,
  has_address: null,
  cart_key: null,
  shopping_address: {
    /* Billing */
    billing_vat_number: null,
    billing_single_employee: null,
    billing_fiscal_code: null,
    billing_company: null,
    billing_email: null,
    billing_recipient_code: null,
    billing_email_pec: null,
    billing_street_name: null,
    billing_street_unit: null,
    billing_street: null,
    billing_city: null,
    billing_province: null,
    billing_country: null,
    billing_zip_code: null,
    billing_telephone_intl_prefix_isocode: null,
    billing_telephone_intl_prefix_number: null,
    billing_telephone_intl_prefix: null,
    billing_telephone_number: null,
    billing_telephone: null,
    /* */
    separate_shipping_address: null,
    /* Shipping */
    shipping_firstname: null,
    shipping_lastname: null,
    shipping_company: null,
    shipping_street_name: null,
    shipping_street_unit: null,
    shipping_street: null,
    shipping_city: null,
    shipping_province: null,
    shipping_country: null,
    shipping_zip_code: null,
    shipping_telephone_intl_prefix: null,
    shipping_telephone_intl_prefix_isocode: null,
    shipping_telephone_intl_prefix_number: null,
    shipping_telephone_number: null,
    shipping_telephone: null,
  },
};

export const state = () => ({ ...model });

export const mutations = {
  setUser(state, data) {
    if (!data) {
      this.$cookies.remove("glmr_token");
      this.$cookies.remove("glmr_id");

      for (let p in state) {
        Vue.set(state, p, null);
      }
      return;
    }

    data = pick(data, Object.keys(model));

    for (let p in data) {
      Vue.set(state, p, data[p]);
    }

    if (data.token) {
      this.$cookies.set("glmr_token", data.token, {
        path: "/",
        maxAge: 60 * 60 * 24 * 365, // 1 anno
      });
    }
    if (data.id) {
      this.$cookies.set("glmr_id", data.id, {
        path: "/",
        maxAge: 60 * 60 * 24 * 365, // 1 anno
      });
    }
  },
};

export const actions = {
  async userLogin({ commit, rootState }, payload) {
    const { data: signinData } = await axios
      .post(`${rootState.wsPath}v2/form_signin?lng=${rootState.lang}`, payload)
      .catch(function (error) {
        console.log("userLogin:", error);
      });

    if (signinData.user_data) {
      commit("setUser", signinData.user_data);
    }

    return signinData.user_data || signinData;
  },

  async userLogout({ commit, rootState }, payload) {
    
    // call to login API
    const { data } = await axios
      .post(`${rootState.wsPath}v2/form_logout`, payload)
      .catch(function (error) {
        console.log("userLogout:", error);
      });

    console.log(data);

    if (data.status == "success") {
      commit("setUser", null);
    }

    return data;
  },

  async userSignup({ commit, rootState }, payload) {
    /*
      code: 'existing_user_login',
    */
    const { data } = await axios
      .post(
        `${rootState.wsPath}v2/form_signup?lng=${rootState.lang}`,
        JSON.stringify({
          ...payload,
        })
      )
      .catch(function (error) {
        console.log(error);
      });

    if (data.token && data.id) {
      commit("setUser", {
        token: data.token,
        id: data.id,
      });
    }
    return data;
  },

  async userUpdate({ commit, rootState }, payload) {
    const { data } = await axios
      .post(
        `${rootState.wsPath}v2/form_update-user?lng=${rootState.lang}`,
        JSON.stringify({
          ...omit(payload, ["confirm_email"]),
        })
      )
      .catch(function (error) {
        console.log(error);
      });

    if (data.user?.token) {
      commit("setUser", data.user);
    }
    return data;
  },

  async userRemove({ commit, rootState }, payload) {
    const { data } = await axios
      .post(
        `${rootState.wsPath}v2/form_remove-user?lng=${rootState.lang}`,
        JSON.stringify({
          ...payload,
        })
      )
      .catch(function (error) {
        console.log(error);
      });
    return data;
  },

  async passwordUpdate({ rootState }, payload) {
    // /it/account/personal-unfo ---> Change password
    const { data } = await axios
      .post(
        `${rootState.wsPath}v2/form_password-update?lng=${rootState.lang}`,
        JSON.stringify({
          ...payload,
        })
      )
      .catch(function (error) {
        console.log(error);
      });
    return data;
  },

  async passwordRecovery({ rootState }, payload) {
    const { data } = await axios
      .post(
        `${rootState.wsPath}v2/form_password-recovery-request?lng=${rootState.lang}`,
        JSON.stringify({
          ...payload,
        })
      )
      .catch(function (error) {
        console.log(error);
      });
    return data;
  },

  async passwordReset({ commit, rootState }, payload) {
    // quando arrivi dalla mail
    const { data } = await axios
      .post(
        `${rootState.wsPath}v2/form_password-renew?lng=${rootState.lang}`,
        JSON.stringify({
          ...payload,
        })
      )
      .catch(function (error) {
        console.log(error);
      });
    return data;
  },

  async setShoppingAddress({ commit, rootState }, payload) {
    // quando arrivi dalla mail

    //  &token=
    const { data } = await axios
      .post(`${rootState.wsPath}v2/form_shopping-address`, {
        ...payload,
        lng: rootState.lang,
      })
      .catch(function (error) {
        console.log(error);
      });

    if (data.data.status == 200) {
      commit("setUser", data.user);
    }

    return data;
  },
};
