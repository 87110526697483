const redirects = [
  {
    from: "/(en|it|de|fr|es)/glamfusion$",
    to: "/xx/materials-and-installation/FU",
  },
  {
    from: "/(en|it|de|fr|es)/glampure$",
    to: "/xx/materials-and-installation/PU",
  },
  { from: "/(en|it|de|fr|es)/diary$", to: "/xx/diary/all" },
  { from: "/(en|it|de|fr|es)/about$", to: "/xx/company" },
  { from: "/(en|it|de|fr|es)/projects$", to: "/xx/diary/projects" },
  {
    from: "/(en|it|de|fr|es)/materials$",
    to: "/xx/materials-and-installation",
  },
  /* {
    from: "/(en|it|de|fr|es)/professional-consulting$",
    to: "/xx/professionals",
  },
  {
    from: "/(en|it|de|fr|es)/contacts/professional-consulting$",
    to: "/xx/contacts/customer-care-service",
  }, */
  {
    from: "/(en|it|de|fr|es)/contacts$",
    to: "/xx/contacts/customer-care-service",
  },
  { from: "/(en|it|de|fr|es)/discover-all$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/download-area/catalogues$", to: "/xx/catalogues" },
  {
    from: "/(en|it|de|fr|es)/showrooms/glamora-milan-showroom$",
    to: "/xx/glamora-showroom",
  },
  {
    from: "/(en|it|de|fr|es)/glamfusion/collection-ix$",
    to: "/xx/creative-collection#CHA09",
  },
  { from: "/(en|it|de|fr|es)/projects/dining$", to: "/xx/diary/projects" },
  { from: "/(en|it|de|fr|es)/projects/hospitality$", to: "/xx/diary/projects" },
  { from: "/(en|it|de|fr|es)/projects/public$", to: "/xx/diary/projects" },
  { from: "/(en|it|de|fr|es)/product/sipario$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/saya$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/horizon$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/livre$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/quisisana$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/elle$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/sun_pop$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/makambo$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/float$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/eupraxia$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/bolshoi$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/raw$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/greenhouse$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/amelie$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/pandora$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/saigon_garden$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/tondo$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/mediterraneo$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/savannah$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/listen$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/turandot$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/greenwood$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/jade$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/borderline$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/twist$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/getaway$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/charis$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/epilogue$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/ritual$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/incanto$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/up__up$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/spleen$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/chalk$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/black_white$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/chrysalis$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/palms$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/glow$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/aquarium$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/destiny$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/pulse$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/prism$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/moth$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/razor$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/bliss$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/vertigo$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/metro$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/a_la_mer$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/blur$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/linosa$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/saphir$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/arena$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/korean_blossom$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/dream$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/diesis$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/duna$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/eterea$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/contemplation$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/bodywall$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/tiled_up$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/alphabet$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/glaze$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/gaudium$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/babylone$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/existence$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/nexus$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/sinfonia$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/regal$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/grid$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/gloria$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/simply$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/aramara$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/ethos$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/wassily$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/amaretto$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/bardiglio$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/roots$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/madama_butterfly$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/somewhere$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/dehors$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/soledad$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/riflesso$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/flap_flap$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/pliplat$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/forms$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/alchemy$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/ischia$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/illusion$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/rough$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/explosio$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/flower$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/pursuit$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/lace$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/matrix$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/capri$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/start$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/little_book$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/memoria$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/spaceman$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/fogg$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/mia$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/twilight$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/pulp_strip$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/atollo$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/yorkshire$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/boulevard$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/kalahari$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/memoire$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/cricket$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/tourbillon$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/frame$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/sakura$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/vision$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/sempervirens$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/akiko$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/blink$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/piazzetta$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/the_dreamer$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/silent$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/convivio$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/pique_nique$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/garden_party$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/eternity$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/gestalt$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/applause$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/kintsugi$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/fujiko$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/ghost$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/lurex$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/green_fingers$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/vitrum$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/nebula$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/ant$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/kokoro$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/elektra$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/serene$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/vita$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/nocturne$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/patchwork$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/summer_walk$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/spilia$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/light_frame$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/jardin_des_plantes$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/piaf$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/presagio$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/dedale$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/highlife$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/terrace$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/kashmir$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/chroma$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/daisy_blue$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/sapphire$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/polonium$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/watercolor$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/art_rock$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/bloom$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/firefly$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/traviata$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/bellagio$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/volage$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/azur$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/imprint$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/cult$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/des244$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/zebra$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/lovers$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/kora$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/ashita$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/mystic$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/nabucco$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/fraction$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/trace$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/esedra$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/amour$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/karibu$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/mantis$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/flow$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/olympe$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/lazy_sundae$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/empathy$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/new_romantic$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/volupte$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/day_dreamer$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/nights$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/opus$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/terramare$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/cats$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/kaleidoscope$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/panacea$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/still_life$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/vichy$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/raquette$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/enigma$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/penombra$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/esprit$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/ego$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/galleria$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/kiss$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/palamidas$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/wood_blend$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/charms$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/magnolias$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/decoupage$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/spectrum$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/dreamliner$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/framed$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/shang$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/touch$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/pigalle$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/wild$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/penelope$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/mistery$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/favola$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/cubic_fern$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/pegase$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/bizzare$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/duende$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/tropicalia$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/belladonna$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/adorni$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/hologram$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/eclipse$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/venus$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/musa$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/diadema$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/paintflowers$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/trip$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/tessere$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/paris_green$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/park$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/radici$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/polare$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/harper$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/etoile$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/pensieri$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/la_cenerentola$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/gobi$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/zeppelin$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/opera$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/circus$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/forest$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/tosca$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/always_good_mood$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/asteroids$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/venice_lights$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/oasis$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/aromas$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/crude$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/nostalgie$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/agio$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/hibiscus$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/sovas$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/kamini$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/lapis$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/shamal$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/prelude$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/comixology$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/primofiore$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/cartesio$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/four_seasons$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/cohen$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/presence$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/agua_de_sueno$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/folium$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/sealife$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/jellyfish$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/soffuso$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/euphoria$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/emperador_dark$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/marea$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/trama$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/otium$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/essenza$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/heavenly_strips$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/blanche$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/tribales$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/amber$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/alma$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/shade$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/afrodisia$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/cafe_de_flore$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/projects/working$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/fragment$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/silva$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/midas$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/mangrove$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/hypnotic$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/flutti$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/natural_high$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/reverie$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/civitas$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/shibumi$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/bubble_chat$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/empire$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/framework$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/lithium$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/nador$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/quisisana$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/elle$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/sun_pop$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/esedra$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/makambo$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/nara$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/tenderness$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/origami$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/mint$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/lullaby$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/privacy$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/dehors$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/aurea$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/gea$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/suiseki$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/jeu$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/flora$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/nostos$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/sensation$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/metamorfosi$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/glow$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/prince_of_wales$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/bittersweet$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/bliss$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/atelier$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/sheer$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/poeme$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/calimala$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/emozioni$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/jazz$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/manta$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/fontelina$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/lunar$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/eterea$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/new_entry$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/wink$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/coral$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/agave$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/fabula$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/dolcevita$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/sinfonia$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/entrance$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/tree$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/tangle$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/weave$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/gravity$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/acropora$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/tatami$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/paris$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/cartagena$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/kensingto$n$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/secret$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/heartprint$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/novel$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/nautilus$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/moonshot$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/afra$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/ballet$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/fishcakes_and_remedies$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/shangai$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/origine$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/caractere$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/jamala$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/disc$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/leaf$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/momoko$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/dusk$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/stratus$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/amable$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/shelter$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/aqualunae$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/koi$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/kila$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/talisman$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/shapes$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/soul$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/nuvola$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/folia$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/aria$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/candy$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/logos$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/momentum$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/piccadilly$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/mundus$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/clouds$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/palamidas$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/graceful$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/suite$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/dogon$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/matinee$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/decorum$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/exotic$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/clone$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/kimono$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/midpoint$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/people$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/notte_bella$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/watermelon$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/allure$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/intarsio$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/pentelikon$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/sycamore$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/greenfields$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/clessidra$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/narciso$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/equatorial_jungle$",
    to: "/xx/advanced-search",
  },
  { from: "/(en|it|de|fr|es)/product/meridien$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/nomad$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/moribana$", to: "/xx/advanced-search" },
  { from: "/(en|it|de|fr|es)/product/whisper$", to: "/xx/advanced-search" },
  {
    from: "/(en|it|de|fr|es)/product/pink_rhapsody$",
    to: "/xx/advanced-search",
  },
  {
    from: "/(en|it|de|fr|es)/discover-all?materials=glamdecor$",
    to: "/xx/materials-and-installation/D",
  },
  {
    from: "/(en|it|de|fr|es)/discover-all?materials=glamfusion$",
    to: "/xx/materials-and-installation/FU",
  },
  {
    from: "/(en|it|de|fr|es)/discover-all?materials=glampure$",
    to: "/xx/materials-and-installation/PU",
  },

  {
    from: "/(en|it|de|fr|es)/discover-all?materials=glamtrace$",
    to: "/xx/materials-and-installation/TR",
  },

  {
    from: "/(en|it|de|fr|es)/discover-all?materials=glamMetal$",
    to: "/xx/materials-and-installation",
  },

  {
    from: "/(en|it|de|fr|es)/discover-all?materials=glamacoustic$",
    to: "/xx/materials-and-installation",
  },
];

export default redirects;
